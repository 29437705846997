import type {AtgResponse} from "@atg-shared/fetch-types";
import atgRequest, {pureFetch} from "@atg-shared/fetch";
import {CALENDAR_SERVICE_URL} from "@atg-shared/service-url";
import * as Storage from "@atg-shared/storage";
import type {CalendarDayApiResponse, GaloppCalendarResponse} from "./types";

/**
 * Constructs the URL for fetching the calendar day information.
 *
 * Build URL to racinginfo/v1/api/calendar/day/ with date and minVersion params.
 * When using this url racingInfo will return an object with @type CalendarAPITypes.CalendarDayResponse
 *
 * @param date - The date for which to fetch the calendar day information in the format `YYYY-MM-DD`. Optional.
 * @param minVersion - The minimum version of the data to fetch. Optional.
 *
 * @returns The constructed URL string for fetching the calendar day information.
 */
export const createDayUrl = (date?: string, minVersion?: number) => {
    const minVersionSuffix = minVersion ? `?minVersion=${minVersion}` : "";
    const dayParam = date ? `/${date}` : ``;
    return `${CALENDAR_SERVICE_URL}/day${dayParam}${minVersionSuffix}`;
};

/**
 * Fetch all games for a calendar day
 *
 * API endpoint: `/services/racinginfo/v1/api/calendar/day/<day>`
 *
 * @param date - The date for which to fetch the calendar day information in the format `YYYY-MM-DD`.
 * @param minVersion - The minimum version of the data to fetch.
 *
 * @returns A promise that resolves to an `AtgResponse` containing the `CalendarDayApiResponse`.
 */
export const fetchDay = (
    date?: string,
    minVersion?: number,
): Promise<AtgResponse<CalendarDayApiResponse>> => {
    if (Storage.getItem("radarDebugger", Storage.SESSION) === "true") {
        return pureFetch<CalendarDayApiResponse>(
            `https://www.atg.se${CALENDAR_SERVICE_URL}/day/${date}?minVersion=${minVersion}`,
        );
    }
    return atgRequest<CalendarDayApiResponse>(createDayUrl(date, minVersion));
};

/**
 * Create URL to /services/racinginfo/v1/api/calendar/gallop with fromDate and toDate params.
 * When using this URL, racingInfo will return an object with @type CalendarAPITypes.GaloppCalendarResponse.
 *
 * @param fromDate - The start date for the calendar range in the format `YYYY-MM-DD`.
 * @param toDate - The end date for the calendar range in the format `YYYY-MM-DD`.
 *
 * @returns The constructed URL string for fetching the gallop calendar.
 */
export const createGaloppCalendarUrl = (fromDate: string, toDate: string) => {
    const sportUrl = `${CALENDAR_SERVICE_URL}/gallop`;
    return `${sportUrl}?from=${fromDate}&to=${toDate}`;
};

/**
 * Fetches the gallop calendar from the API endpoint `/services/racinginfo/v1/api/calendar/gallop`.
 *
 * Fetch from API endpoint: `/services/racinginfo/v1/api/calendar/gallop`
 *
 * @param fromDate - The start date for the calendar range in the format `YYYY-MM-DD`.
 * @param toDate - The end date for the calendar range in the format `YYYY-MM-DD`.
 *
 * @returns A promise that resolves to an `AtgResponse` containing the `GaloppCalendarResponse`.
 */
export const fetchGaloppCalendar = (fromDate: string, toDate: string) =>
    atgRequest<GaloppCalendarResponse>(createGaloppCalendarUrl(fromDate, toDate));
