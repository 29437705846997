import type {GlobalVideoState} from "../video";

// is startlist hidden?
export const isVideoOnly = (state: GlobalVideoState) =>
    state?.video?.videoFrame.videoOnly;

export const isVideoFrameExpanded = (state: GlobalVideoState) =>
    state?.video?.videoFrame.videoFrameExpanded;

// Archive/warmup video
export const getGraphicsMedia = (state: GlobalVideoState) =>
    state?.video?.warmupVideo.graphicsMedia;

export const video = (state: GlobalVideoState) => state.video;

const getWarmupVideoState = (state: GlobalVideoState) => state?.video?.warmupVideo;

// Archive/warmup video
export const getMediaId = (state: GlobalVideoState) =>
    getWarmupVideoState(state)?.mediaId;

export const getMediaIds = (state: GlobalVideoState) =>
    getWarmupVideoState(state)?.mediaIds;

export const getTrack = (state: GlobalVideoState) => getWarmupVideoState(state)?.track;
export const getGameId = (state: GlobalVideoState) => getWarmupVideoState(state)?.gameId;
