export enum RgsLimitTypeEnum {
    LOSS = "netLossLimits",
    TIME = "playTimeLimits",
    LOGIN = "loginTimeLimits",
}

export enum VerticalEnum {
    CASINO = "CASINO",
    HORSE_BETTING = "HORSE_BETTING",
    SPORTSBOOK = "SPORTSBOOK",
}
