import {broadcastAction} from "atg-store-addons";
import type {VideoObject, VideoArchive} from "./video";
import {
    EXPAND_VIDEO_FRAME,
    TOGGLE_VIDEO_FRAME_EXPANDED,
    SET_VIDEO_ONLY,
    SET_POPOUT_FRAME,
    MEDIA_WARMUP,
    MEDIA_WARMUP_WINDOW,
    MEDIA_ARCHIVE,
    MEDIA_ARCHIVE_WINDOW,
    OPEN_VIDEO_POPUP,
    CLOSE_VIDEO_POPUP,
} from "./videoActionConstants";

export const expandVideoFrame = (expanded = true) =>
    broadcastAction({
        type: EXPAND_VIDEO_FRAME,
        payload: {
            expanded,
        },
    });

export const toggleExpanded = () => ({
    type: TOGGLE_VIDEO_FRAME_EXPANDED,
});

export const setVideoOnly = (isVideoOnly: boolean) => ({
    type: SET_VIDEO_ONLY,
    payload: {
        isVideoOnly,
    },
});

export const setPopoutFrame = (isPopoutFrame: boolean) => ({
    type: SET_POPOUT_FRAME,
    payload: {
        isPopoutFrame,
    },
});

export const setMediaWarmupWindow = (payload: VideoObject) => ({
    type: MEDIA_WARMUP_WINDOW,
    payload,
});

export const setMediaWarmup = (payload: VideoObject) => ({
    type: MEDIA_WARMUP,
    payload,
});

export const setMediaArchiveWindow = (payload: VideoArchive) => ({
    type: MEDIA_ARCHIVE_WINDOW,
    payload,
});

export const setMediaArchive = (payload: VideoArchive) =>
    broadcastAction({
        type: MEDIA_ARCHIVE,
        payload,
    });

export const openVideoPopup = (playerName: string) => ({
    type: OPEN_VIDEO_POPUP,
    payload: playerName,
});

export const closeVideoPopup = (playerName: string) => ({
    type: CLOSE_VIDEO_POPUP,
    payload: playerName,
});
