import dayjs from "dayjs";
import type {CalendarAPITypes, GameAPITypes} from "@atg-horse-shared/racing-info-api";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {
    flow,
    flatMap,
    filter,
    compact,
    keyBy,
    flatten,
    find,
    join,
    sortBy,
    map,
} from "lodash/fp";
import {forEach, every, reduce} from "lodash";
import * as GameTypes from "@atg-horse-shared/game-types";
import type {GameType, GameTypeWithoutLegacy} from "@atg-horse-shared/game-types";
import {parseGameId} from "@atg-horse-shared/utils/gameid";
import log from "@atg-shared/log";

dayjs.extend(customParseFormat);

// @ts-expect-error
const filterNoCap = filter.convert({cap: false});

export const GetRaceStatus = {
    upcoming: "upcoming",
    ongoing: "ongoing",
    results: "results",
    cancelled: "cancelled",
};

export type CalendarDayGame = Omit<CalendarAPITypes.CalendarDayResponseGame, "tracks"> & {
    tracks: Array<GameAPITypes.CalendarTrack>;
};

/**
 * Same as `CalendarDayResponse`, but after transforming the game data with `addGameTypeToCalendarGames()`
 */
export type CalendarDay = {
    date: string;
    tracks: GameAPITypes.CalendarTrack[];
    games: {[key in GameType]: CalendarGame[]};
    version?: number;
    currentVersion?: number;
};

export type CalendarGame = {
    gameType: GameTypeWithoutLegacy;
    url?: string;
} & CalendarAPITypes.CalendarDayResponseGame;

export function findGameById(
    day: CalendarDay,
    gameId: string,
): CalendarGame | undefined | null {
    const {gameType = ""} = parseGameId(gameId) || {};

    const games = day.games[gameType as GameType];

    if (!games) {
        log.error("findGameById: could not find game by id", {gameId});
        return null;
    }

    return games.find((game: CalendarGame) => game.id === gameId);
}

export const findGamesByTypeAndTrack = (
    day: CalendarDay,
    gameType: GameType,
    trackId: number,
): Array<CalendarGame> => {
    const gameTypeGames = day.games[gameType];
    if (!gameTypeGames) return [];

    return gameTypeGames.filter((game) => game.tracks.indexOf(trackId) >= 0);
};

export function findRaceById(
    day: CalendarDay,
    raceId: string,
): CalendarAPITypes.CalendarRace {
    return flow(
        flatMap((track: any) => track.races),
        compact,
        find((race) => race.id === raceId),
    )(day.tracks);
}

export function findRacesByIds(
    day: CalendarDay,
    raceIds: Array<string>,
): Array<CalendarAPITypes.CalendarRace> {
    const races = flow(
        flatMap((track: GameAPITypes.CalendarTrack) => track.races),
        compact,
        filter((race) => raceIds.includes(race.id)),
        keyBy("id"),
    )(day.tracks);

    // @ts-expect-error
    return compact(raceIds.map((raceId) => races[raceId]));
}

export function findTrackById(
    day: CalendarDay,
    trackId: number,
): GameAPITypes.CalendarTrack | undefined {
    return day.tracks.find((track) => track.id === trackId);
}

export function getDivisionGamesContainingRaceId(
    day: CalendarDay,
    raceId: string | undefined,
    firstRaceOnly = false,
) {
    if (!day.games || !raceId) return null;

    return flow(
        filterNoCap((_game: CalendarGame, gameType: GameType) =>
            GameTypes.isDivisionGameType(gameType),
        ),
        flatten,
        filter<CalendarGame>((game: CalendarGame) => {
            const {races} = game;
            if (firstRaceOnly) return races[0] === raceId;

            return races.includes(raceId);
        }),
    )(day.games);
}

export function isNextRace(
    day: CalendarDay | undefined,
    race: GameAPITypes.GameRace,
): boolean {
    if (!day) return false;
    const {tracks} = day;
    const raceTrack = race.track;
    const calendarTrack = find({id: raceTrack.id}, tracks);
    const nextRaceID = calendarTrack && calendarTrack.nextRace;
    if (!nextRaceID) return false;
    return race.id === nextRaceID;
}

export function findBiggestCalendarGameForCalendarDay(
    day?: CalendarDay,
): CalendarGame | null {
    if (!day) return null;

    const allGameTypesForDay = Object.keys(day.games) as Array<GameType>;
    const sortedGameTypesByPriority = sortBy(
        (gameType) => GameTypes.gameTypePriorityOrder[gameType],
        allGameTypesForDay,
    );
    const biggestGameTypeForDay = sortedGameTypesByPriority[0];
    return day.games[biggestGameTypeForDay][0];
}

export const parseCalendarDate = (dateString?: string): string | null => {
    if (!dateString) return null;

    const date = dayjs(dateString, "YYYY-MM-DD", true);
    const isValid = date.isValid() && date.isAfter("2011-04-30");

    if (!isValid) return null;

    return date.format("YYYY-MM-DD");
};

export const addGameTypeToCalendarGames = (
    calendarDay: CalendarAPITypes.CalendarDayApiResponse,
): CalendarDay => {
    const {games: calendarGames, ...rest} = calendarDay;
    const transformedGames: Record<GameType, Array<CalendarGame>> = {} as Record<
        GameType,
        Array<CalendarGame>
    >;
    forEach(
        calendarGames,
        // @ts-expect-error
        (games: Array<CalendarDayResponseGame>, gameType: GameTypeWithoutLegacy) => {
            // @ts-expect-error
            transformedGames[gameType] = map(
                (game: CalendarAPITypes.CalendarDayResponseGame): CalendarGame => ({
                    ...game,
                    gameType,
                }),
                games,
            );
        },
    );

    const transformedDay: CalendarDay = {
        ...rest,
        games: transformedGames,
    };

    return transformedDay;
};

export const isAllGamesScheduled = (day?: CalendarDay): boolean => {
    if (!day) return true;
    // @ts-ignore
    return reduce(
        // @ts-ignore
        day.games,
        // @ts-ignore
        (acc, gameTypeGames) =>
            acc && every(gameTypeGames, (game) => game.status === "scheduled"),
        // @ts-ignore
        true,
    );
};

export const isSingleRaceGameAvailable = (
    day: CalendarDay,
    gameType: GameType,
    raceId: string,
): boolean =>
    find((calendarGame) => calendarGame.races[0] === raceId, day.games[gameType]) !==
    undefined;

export const getGamesTrackNames = (
    day: CalendarDay,
    game: CalendarGame,
    seperator = " & ",
): string =>
    join(seperator)(
        map("name")(
            compact(map((trackID: number) => findTrackById(day, trackID))(game.tracks)),
        ),
    );
