import {takeLatest, call, put, select, take} from "redux-saga/effects";
import type {Saga, SagaIterator} from "redux-saga";
import {isEqual, find} from "lodash/fp";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {isValidGameId} from "@atg-horse-shared/utils/game";
import * as GameTypes from "@atg-horse-shared/game-types";
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as ProductActions from "@atg-horse/product-pages/domain/productActions";
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as ProductSelectors from "@atg-horse/product-pages/domain/productSelectors";
import {RouterSelectors} from "@atg-shared/router";
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as CalendarActions from "@atg-horse-shared/calendar/domain/calendarActions";
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as CalendarSelectors from "@atg-horse-shared/calendar/domain/calendarSelectors";
import {NavigationSelectors} from "@atg-horse-shared/startlist-navigation";
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as GameUrl from "@atg-horse-shared/utils/url";
import {type CalendarAPITypes} from "@atg-horse-shared/racing-info-api";
import {type GameStatus, type GameType} from "@atg-horse-shared/game-types";
import browserHistory from "atg-history";
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as GameActions from "atg-horse-game/domain/gameActions";
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as GameSelectors from "atg-horse-game/domain/gameSelectors";
// eslint-disable-next-line @nx/enforce-module-boundaries
import type {ParsedPath} from "atg-horse-game/domain/gameUrl";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {type ChangeGameAction} from "atg-horse-game/domain/gameActions";
import * as GameRouteHelpers from "./gameRouteHelpers";

export type FetchOrReturnProps = {
    fetchAction: () => any;
    isOk: (state: any) => any;
    selector: (state: any) => any;
    receiveActionType?: any;
};

type GameFetchResult = null | {
    races: {number: number; status: GameStatus}[];
    type: GameType;
};

export function* takeWithContext(
    actionType: any,
    context: any | null | undefined,
): SagaIterator<void> {
    let action;
    do {
        action = yield take(actionType);
    } while (!isEqual(action.context, context));
    return action;
}

export function* fetchOrReturn({
    fetchAction,
    isOk,
    selector,
    receiveActionType,
}: FetchOrReturnProps): SagaIterator<void> {
    const isOkResult = yield select(isOk);
    if (isOkResult) return yield select(selector);
    const fetchActionObject = fetchAction();
    const receiveAction = receiveActionType || fetchActionObject.payload.receiveAction;

    yield put(fetchActionObject);
    const context = fetchActionObject.payload ? fetchActionObject.payload.context : null;
    const action = yield call(takeWithContext, receiveAction, context);
    return action.error ? null : yield select(selector);
}

export const getGameFetchOptions = (gameId: string): FetchOrReturnProps => ({
    isOk: (state) => GameSelectors.getGameById(state, gameId),
    selector: (state) => GameSelectors.getGameById(state, gameId),
    fetchAction: () => GameActions.fetchGame(gameId),
    receiveActionType: GameActions.RECEIVE_GAME,
});

export function* resolvedGameRoute(
    gameId: string,
    parsedGamePath: ParsedPath,
    raceStatus?: CalendarAPITypes.RaceStatus,
): SagaIterator<void> {
    const gameFetchOptions = yield call(getGameFetchOptions, gameId);
    const game = (yield call(fetchOrReturn, gameFetchOptions)) as GameFetchResult;

    if (!game) {
        yield put(GameActions.setGameIdMissing());
        return;
    }

    // Change VXY divisionNumber to current ongoing division
    let ongoingDivisionNumber = null;
    if (game && GameTypes.isVXYGameType(game.type) && raceStatus === "ongoing") {
        const raceIndex = game.races.findIndex(
            (race) => race.status === "ongoing" || race.status === "upcoming",
        );

        if (raceIndex > -1) {
            ongoingDivisionNumber = raceIndex + 1;
        }
    }

    const isRaket = game.type === "raket";
    const resolvedUrl = GameTypes.isSingleRaceGameType(game.type)
        ? yield call(GameUrl.buildSingleRaceUrl, {
              game,
              showResults: parsedGamePath.result,
              type: parsedGamePath.type,
              race: game.races[0],
              addQuery: true,
          })
        : yield call(GameUrl.buildDivisionGameUrl, {
              game,
              showResults: parsedGamePath.result,
              useRaceNumberInsteadOfleg: isRaket,
              legNumber: isRaket
                  ? parsedGamePath.raceNumber
                  : ongoingDivisionNumber ?? parsedGamePath.divisionNumber,
              type: parsedGamePath.type,
              addQuery: true,
          });

    yield call(browserHistory.replace, resolvedUrl);
}

export function* loadGameFromCalendarRoute(
    calendarDate: string,
    parsedGamePath: ParsedPath,
    raceStatus?: CalendarAPITypes.RaceStatus,
): SagaIterator<void> {
    if (!calendarDate) {
        yield put(GameActions.setGameIdMissing());
        return;
    }

    const calendarDay = yield select(CalendarSelectors.getCalendarDay, calendarDate);
    const trackId = yield select(GameSelectors.getCurrentTrackId);
    const resolvedGameId = yield call(
        GameRouteHelpers.getGameIdFromCalendarDay,
        calendarDay,
        parsedGamePath,
        trackId,
    );

    if (!resolvedGameId) {
        // All games are scheduled
        yield call(browserHistory.replace, `/spel/kalender/${calendarDate}`);
        yield put(GameActions.setCurrentGameId(null));
        return;
    }
    yield put(GameActions.setCurrentGameId(resolvedGameId));
    yield call(resolvedGameRoute, resolvedGameId, parsedGamePath, raceStatus);
}

export const getScheduleFetchOptions = (gameType: string): FetchOrReturnProps => ({
    isOk: (state) => ProductSelectors.isScheduleOk(state, gameType),
    selector: (state) => ProductSelectors.getGameScheduleByGameType(state, gameType),
    fetchAction: () => ProductActions.fetchGameSchedule(gameType),
});

export function* loadScheduleRoute(parsedGamePath: ParsedPath): SagaIterator<void> {
    const {gameType} = parsedGamePath;

    if (!gameType) {
        yield put(GameActions.setGameIdMissing());
        return;
    }
    const scheduleFetchOptions = yield call(getScheduleFetchOptions, gameType);
    const schedule = yield call(fetchOrReturn, scheduleFetchOptions);

    if (!schedule) {
        yield put(GameActions.setGameIdMissing());
        return;
    }

    const closestGame = yield call(
        GameRouteHelpers.getCalendarDateFromSchedule,
        schedule,
    );

    if (!closestGame) {
        yield put(GameActions.setGameIdMissing());
        return;
    }
    yield put(GameActions.setCurrentGameId(closestGame.id));
    yield call(resolvedGameRoute, closestGame.id, parsedGamePath);
}

export const getCalendarFetchOptions = (calendarDate: string): FetchOrReturnProps => ({
    isOk: (state) => CalendarSelectors.getCalendarDay(state, calendarDate),
    selector: (state) => CalendarSelectors.getCalendarDay(state, calendarDate),
    fetchAction: () => CalendarActions.fetchCalendar(calendarDate),
});

export function* loadCalendarRoute(
    calendarDate: string,
    parsedGamePath: ParsedPath,
    raceStatus?: CalendarAPITypes.RaceStatus,
): SagaIterator<void> {
    const calendarFetchOptions = yield call(getCalendarFetchOptions, calendarDate);
    const calendarDay = yield call(fetchOrReturn, calendarFetchOptions);

    if (!calendarDay) {
        yield put(GameActions.setGameIdMissing());
        return;
    }

    yield call(loadGameFromCalendarRoute, calendarDate, parsedGamePath, raceStatus);
}

export function* gameRoute({
    payload: {raceStatus},
}: ChangeGameAction): SagaIterator<void> {
    const pathname = yield select(RouterSelectors.getPath);
    // horse game url should start with /spel
    if (pathname.indexOf("/spel") !== 0) return;

    const pathWithoutSpel = GameUrl.getGamePath(pathname);
    const parsedGamePath = yield call(GameUrl.parsePathForRouting, pathWithoutSpel);
    if (!pathWithoutSpel.length) {
        // /spel
        const lastVisitedGameType = yield select(GameRouteHelpers.getLastVisitedGameType);
        if (lastVisitedGameType) {
            const lastVisitedGameUrl = yield select(
                NavigationSelectors.getLastVisitedStartlistForGameType,
                lastVisitedGameType,
            );
            const lastVisitedGameId = yield select(
                NavigationSelectors.getLastVisitedGameIdForGameType,
                lastVisitedGameType,
            );

            if (lastVisitedGameUrl) {
                yield call(browserHistory.replace, lastVisitedGameUrl);
                yield put(GameActions.setCurrentGameId(lastVisitedGameId));
                return;
            }
        }
    }
    const validGameId = find(isValidGameId)(pathWithoutSpel);
    if (validGameId) {
        // spel/gameId or reducerat/spel/gameId
        yield put(GameActions.setCurrentGameId(validGameId));
        yield call(resolvedGameRoute, validGameId, parsedGamePath);

        return;
    }

    const calendarDate = GameRouteHelpers.getCalendarDateFromPath(pathWithoutSpel);
    if (!calendarDate) {
        // /spel/gameType
        yield call(loadScheduleRoute, parsedGamePath);
        return;
    }

    yield call(loadCalendarRoute, calendarDate, parsedGamePath, raceStatus);
}

export default function* gameRouteSaga():
    | Saga<Array<any>>
    | Generator<any, any, unknown> {
    yield takeLatest(GameActions.CHANGE_GAME, gameRoute);
}
