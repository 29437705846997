import {
    map,
    filter,
    flow,
    find,
    uniqBy,
    keys,
    flatten,
    reduce,
    join,
    isArray,
    sortBy,
    reverse,
    includes,
    isEmpty,
} from "lodash/fp";
import {call, takeEvery, put, select} from "redux-saga/effects";

import type {SagaIterator} from "redux-saga";
import {isApp} from "@atg-shared/system";
import log, {serializeError} from "@atg-shared/log";
import {type GameType, GameTypes} from "@atg-horse-shared/game-types";
import type {
    BetGradingResponse,
    BoostSelections,
    RaketSystem,
    HorseSelection,
} from "@atg-horse-shared/bet-types";
import {mapHarryFlavorByLegacyName} from "@atg-horse-shared/utils/harry";
import {getGameTypeByGameId, parseGameId} from "@atg-horse-shared/utils/gameid";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {receivedGradingBet} from "@atg-horse/grading/domain/gradingActions";
import {fetchGame, type GameAPITypes} from "@atg-horse-shared/racing-info-api";
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as betActionTypes from "@atg-horse-shared/horse-bet-types";
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as BetAPI from "@atg-horse-shared/bet-api";
import {receiveCorrectedBet, receiveCorrectedBetError} from "../redux/betactions";
import {getBet} from "../redux/betSelectors";
import * as BetActions from "./betActions";
import * as Bet from "./legacyBet";
import * as BetUtils from "./bet";

// @ts-expect-error convert does not exist on map
const mapWithIndex = map.convert({cap: false});

const extractRace = (game: GameAPITypes.Game) => {
    const raceNumber = parseGameId(game.id)?.raceNumber;
    return find({number: Number(raceNumber)}, game.races);
};

export const formGameTracks = (game: GameAPITypes.Game) =>
    flow([
        map((race: any) => ({
            id: race.track.id,
            name: race.track.name,
        })),
        uniqBy("id"),
    ])(game.races);

export const searchHorseName = (
    horseNumber: number,
    raceIndex: number,
    game: GameAPITypes.Game,
) => {
    const {starts} = game.races[raceIndex];
    const start = find({number: horseNumber}, starts);
    return start?.horse?.name;
};

/*
 * will extract positions from game race
 * positions is in the following format: [ [1, 2], [3], [4], [5], [6] ]
 * (in case of dead heat, first element can contain several winning horses)\
 * we need to repeat the first item by item.lenth times.
 *
 */
export const formPositionResults = (game: GameAPITypes.Game) => {
    const race = extractRace(game);
    const positions =
        race?.pools && game.type === "top7" && race?.pools[game.type]?.result?.winners;

    if (!positions || positions.length === 0) return null;

    let curNumber = 1; // "positionResults" are 1-based
    return reduce(
        (acc: any, positionRow) => {
            // we need to format positionRow from [x, y] to [{number: x}, {number: y}]
            const formattedPositionRow = map(
                (position) => ({
                    number: position,
                }),
                positionRow,
            );
            let i = 0;
            // formattedPositionRow is a row we have to repeat formattedPositionRow.length times.
            while (i < formattedPositionRow.length) {
                acc[curNumber] = formattedPositionRow;
                curNumber += 1;
                i += 1;
            }

            return acc;
        },
        {},
        positions,
    );
};

const formHorse = (
    horse: HorseSelection,
    raceIndex: number,
    game: GameAPITypes.Game,
    noWin?: boolean,
) => {
    const formedHorse = {
        number: horse.horseNumber,
        horse: {name: searchHorseName(horse.horseNumber, raceIndex, game)},
        scratched: includes("SCRATCHED", horse.selectedHorseStatus),
    };

    if (noWin) {
        return formedHorse;
    }

    return {...formedHorse, win: includes("CORRECT", horse.selectedHorseStatus)};
};

const calculateResultForSneak = (
    pools: GameAPITypes.RacePools | null | undefined,
    betType: GameType,
) => {
    if (!pools) return false;

    const betTypeToUse = includes(betType, [GameTypes.vp, GameTypes.raket])
        ? GameTypes.vinnare
        : betType;
    const pool = pools[betTypeToUse];
    const result = pool?.result;
    return !isEmpty(result?.winners);
};

export const formRaces = (bet: BetGradingResponse, game: GameAPITypes.Game) =>
    mapWithIndex((selection: any, i: number) => {
        const index = bet.v3LegNumber ? bet.v3LegNumber - 1 : i;
        return {
            bets: flow(
                filter((horse: any) => !includes("RESERVE", horse.selectedHorseStatus)),
                map((horse) => formHorse(horse, index, game)),
            )(selection),
            // used only for tvilling
            baseBets:
                bet.baseSelection &&
                map((horse) => formHorse(horse, index, game), bet.baseSelection),

            raceNumber: game.races[index]?.number,
            // actual played reserves after all scratches. Contrast to the bet.reserves on some bet types, that indicate what reserves user has selected.
            reserves: [
                // take reserves first
                ...map(
                    (reserve) => ({
                        number: reserve.horseNumber,
                        win: flow([
                            find({horseNumber: reserve.horseNumber}),
                            (horse) => includes("CORRECT", horse?.selectedHorseStatus),
                        ])(selection),
                        horse: {
                            name: searchHorseName(reserve.horseNumber, index, game),
                        },
                        scratched: reserve.reserveHorseStatus === "SCRATCHED",
                    }),
                    bet?.reserves?.[index] ?? [],
                ), // and combine with the given reserves which are present only in selections
                ...flow([
                    filter(
                        (horse: any) =>
                            includes("RESERVE", horse.selectedHorseStatus) && // take reserves
                            !includes("SCRATCHED", horse.selectedHorseStatus) && // just in case, ensure it is not scratched
                            !find(
                                // skip if the horse is already in reserves to not have duplicates
                                {horseNumber: horse.horseNumber},
                                bet?.reserves?.[index] || [],
                            ),
                    ),
                    map((horse: any) => ({
                        number: horse.horseNumber,
                        win: includes("CORRECT", horse.selectedHorseStatus),
                        horse: {
                            name: searchHorseName(horse.horseNumber, index, game),
                        },
                        // given means that the reserve is automatically assigned if the manual reserve is missing or scratched
                        given: true,
                    })),
                ])(selection),
            ],
            result: calculateResultForSneak(game.races[index].pools, bet.betType),
            results: game.races[index].pools?.[bet.betType]?.result?.winners,
            trackId: game.races[index]?.track?.id,
            positionResults: game.type === "top7" && formPositionResults(game),
            mediaId: game.races[index].mediaId,
            cancelled: game?.races?.[index]?.status === "cancelled",
        };
    }, bet.selections);

const formRacesForTrioOrKomb = (bet: BetGradingResponse, game: GameAPITypes.Game) => {
    const formBets = (index: number) =>
        bet?.selections[index] &&
        map(
            (horse) => ({
                number: horse.horseNumber,
                horse: {name: searchHorseName(horse.horseNumber, 0, game)},
                scratched: includes("SCRATCHED", horse.selectedHorseStatus),
                win: includes("CORRECT", horse.selectedHorseStatus),
                cancelled: game?.races?.[index]?.status === "cancelled",
            }),
            bet.selections[index],
        );
    const race = extractRace(game);
    return [
        {
            firstPlaceBets: formBets(0),
            secondPlaceBets: formBets(1),
            thirdPlaceBets: formBets(2),
            result: race?.status === "results",
            raceNumber: race?.number,
            trackId: race?.track?.id,
            mediaId: race?.mediaId,
        },
    ];
};

export const formRacesForRaket = (bet: BetGradingResponse, game: GameAPITypes.Game) =>
    map((selection) => {
        const raceIndex = game.races.findIndex(
            (race) => race.number === selection[0].raceNumber,
        );
        const race = game.races[raceIndex];

        return {
            id: race?.id,
            trackId: race?.track?.id,
            raceNumber: selection[0]?.raceNumber ?? "", // receipt table crashes if undefined
            result: race?.status === "results" || race?.status === "cancelled", // a cancelled race also counts as having a result
            cancelled: race?.status === "cancelled",
            bets: map((horse) => formHorse(horse, raceIndex, game), selection),
            betType: selection[0]?.betType?.toLowerCase(),
        };
    }, bet.selections);

export const formBoxedBets = (bet: BetGradingResponse, game: GameAPITypes.Game) => {
    const {groupSelections, selections} = bet;

    if (!selections?.[0]) return null;
    if (!groupSelections?.[0]) return null;

    const results = formPositionResults(game);

    return mapWithIndex(
        (group: any, index: number) =>
            group.map((horse: any) => {
                const startInfo = game.races[0]?.starts?.find(
                    (start) => start.number === horse.horseNumber,
                );

                return {
                    number: horse.horseNumber,
                    horse: {
                        name: startInfo?.horse?.name,
                    },
                    scratched: includes("SCRATCHED", horse.selectedHorseStatus),
                    moved: Boolean(horse.moved),
                    win: Boolean(
                        !horse.moved &&
                            results &&
                            results?.[index + 1]?.find(
                                (winner: any) => winner.number === horse.horseNumber,
                            ),
                    ),
                };
            }),
        groupSelections,
    );
};

export const formPayments = (
    bet: BetGradingResponse,
    game: GameAPITypes.Game,
    customPool?: // used only for forming vp(a combination of vinnare and plats)
    GameType,
) => {
    // cannot calculate the payments yet.
    if (game.status === "ongoing") return null;

    const race = extractRace(game);

    const isTop7 = game.type === GameTypes.top7;

    // different than in other combination games, LD/DD have their pools winners in game.pools, not race.pools
    const isLDDD = game.type === GameTypes.ld || game.type === GameTypes.dd;

    // There is no winSummary in singleLeg bet type except Top7
    if (bet.winSummary) {
        // if the game is multi leg or top7
        const extractPayout = (key: any) => {
            // @ts-expect-error
            if (game?.pools[bet.betType]?.result?.payouts) {
                // @ts-expect-error
                return game.pools[bet.betType].result.payouts[key];
            }
            // Top7 special case
            // @ts-expect-error
            return race?.pools[bet.betType]?.result?.payouts[key];
        };

        return flow([
            keys,
            map((key: any) => {
                if (isTop7) {
                    return {
                        correct: Number(key),
                        winning: String(bet.winSummary?.winningElements[key].rows),
                        amount: extractPayout(key)?.payout || 0,
                        jackpot: extractPayout(key)?.jackpot,
                    };
                }
                const amount = extractPayout(key)?.payout || 0;
                const boostMultiplier =
                    bet.winSummary?.winningElements[key].boostMultiplier;
                const boostAmount = boostMultiplier && amount * boostMultiplier;

                // in old api, boost multiplier was further multiplied by 100.
                // so the choice is either to multiply by 100 here, or check if we are using new api on every usage of boost multiplier.
                const legacyBoostMultiplier = boostMultiplier && boostMultiplier * 100;

                return {
                    correct: Number(key),
                    winning: String(bet.winSummary?.winningElements[key].rows),
                    amount,
                    jackpot: extractPayout(key)?.jackpot,
                    boostMultiplier: legacyBoostMultiplier,
                    boostAmount,
                };
            }),
            sortBy("correct"), // sorted in ascending order
            reverse,
        ])(bet.winSummary?.winningElements);
    }

    // if the game is single leg or LD/DD
    const winners = isLDDD
        ? (game.pools && bet.betType && game.pools[bet.betType]?.result?.winners) || []
        : (race?.pools &&
              bet.betType &&
              race?.pools[customPool || bet.betType]?.result?.winners) ||
          [];

    // single leg winners can be an array or an object with {first: value, second: value ...}
    if (isArray(winners)) {
        return flow([
            map((winner: any) => ({
                winning: winner.combination
                    ? join("/", winner.combination)
                    : String(winner.number),

                dividend: winner.odds,
                betType: customPool || bet.betType,
            })),
            sortBy("winning"), // when it's dead heat sort ascending by the horse number
        ])(winners);
    }
    // if it's using the object format, then dead heat is not even shown! no need to sort it
    return flow([
        keys,
        // @ts-expect-error
        map((key) => winners[key]),
        flatten,
        map((winner: any) => ({
            winning: String(winner.number),
            dividend: winner.odds,
            betType: customPool || bet.betType,
        })),
    ])(winners);
};

export const extractBoostCode = (boostSelections: BoostSelections) =>
    flow([map((boostSelection: any) => boostSelection.boostNumber), join("")])(
        boostSelections,
    );

const removeDateFromBetId = (betId: string): string =>
    betId.slice(betId.indexOf("_") + 1);

export const transformRaketSystemToOldFormat = (raketSystem: RaketSystem) => {
    switch (raketSystem) {
        case "TWO_OF_THREE": {
            return "2of3";
        }
        case "TWO_OF_FOUR": {
            return "2of4";
        }
        case "THREE_OF_FOUR": {
            return "3of4";
        }
        case "THREE_OF_FIVE": {
            return "3of5";
        }
        case "FOUR_OF_FIVE": {
            return "4of5";
        }
        default:
            return "simple";
    }
};

export function* receiveReceiptResult(betId: string): SagaIterator<any> {
    const localBet = yield select((state) => getBet(state, betId));
    if (localBet && !Bet.hasPendingData(localBet) && localBet.type !== GameTypes.V3) {
        // optimization. Doesn't call the easily overloaded /services/v1/user/bets api when it's not neccesary
        // however skip the cache if there is possibility for more data or bet is V3 (since there are edge cases with terminal bets)
        return localBet;
    }

    // For now, only the app will not use new bet history and fetch data from the old api.
    const hasNewBetHistory = !isApp;

    if (hasNewBetHistory) {
        const {data: bet} = yield call(
            BetAPI.fetchReceiptResultNewApi,
            removeDateFromBetId(betId),
        );

        if (bet.harryBoyType) {
            bet.harryBoyType = mapHarryFlavorByLegacyName(bet.harryBoyType);
        }

        const {data: game} = yield call(fetchGame, bet.gameId);

        yield put(receivedGradingBet(betId, bet, game));

        game.type = getGameTypeByGameId(game.id);
        game.date = parseGameId(game.id)?.date;
        game.tracks = formGameTracks(game);
        game.startTime = game.races[0].startTime;
        if (bet.betMethod === "ONLY_VX") {
            bet.betMethod = "onlyVx";
        }
        if (bet.betMethod === "CAMPAIGN" || bet.betMethod === "HARRY") {
            bet.betMethod = "harry";
        }
        if (bet.betMethod === "FLEX") {
            bet.betMethod = "flex";
        }

        // apparently, payoutStatus "PENDING" will mean that the payment is delayed. Should happen only for Ombud (terminal) bets.
        if (bet.ownerPayout?.payoutStatus === "PENDING") {
            bet.payoutDelayed = {
                amount: bet.ownerPayout.payoutAmount,
            };
        }
        // We agreed that the betType will be capitalized in the new api. When removing this, please change betDefs.js keys as well
        bet.betType = game.type;

        let formedRaces = null;
        if (bet.betType === "komb" || bet.betType === "trio") {
            formedRaces = formRacesForTrioOrKomb(bet, game);
        } else if (bet.betType === "raket") {
            formedRaces = formRacesForRaket(bet, game);
        } else {
            formedRaces = formRaces(bet, game);
        }

        let payments = null;
        switch (bet.betType) {
            case "vp":
                payments = flatten([
                    formPayments(bet, game, "vinnare"),
                    formPayments(bet, game, "plats"),
                ]);
                break;

            case "raket":
                payments = null;
                break;

            default:
                payments = formPayments(bet, game);
                break;
        }

        // if the game is still ongoing, payments are not yet calculated
        const hasPayed = bet.ownerPayout?.payoutStatus === "PAID";
        const isSharedOrShopShared = Boolean(bet.shareInfo);

        return {
            ...bet,
            game: {...game, cancelled: game.status === "cancelled"},
            checkable: bet.gradingAvailable, // this is an equivalent in a new HBH
            totalPay: isSharedOrShopShared
                ? bet.systemPayout?.payoutAmount
                : bet.ownerPayout?.payoutAmount,
            systems: bet.numberOfSystems,
            // raket can't have combinations but the old response returned 1, and for legacy reasons this is maintained here as well.
            combinations: bet.betType === "raket" ? 1 : bet.numberOfCombinations,
            cost: bet.systemCost,
            id: `${BetUtils.removeTimePart(bet.betTime) || ""}_${bet.serialNumber}`,
            races: formedRaces,
            timestamp: bet.betTime,
            type: bet.betType,
            // used in Dividend and will show "Utbetalt" if defined
            payout: hasPayed
                ? {amount: bet.ownerPayout?.payoutAmount, fee: bet.fee}
                : null,
            payments,
            harryFlavour: bet.harryBoyType,
            boxedBets: formBoxedBets(bet, game),
            shareInfo: bet.shareInfo && {
                ...bet.shareInfo,
                ownedNrShares: bet.shareInfo?.ownedShares,
                totalNrShares: bet.shareInfo?.totalShares,
            },
            flexValue: bet.flexPercent,
            boostInfo: bet.boost && {
                cost: bet.boostCost,
                // user's boost number
                code: bet.boostSelections && extractBoostCode(bet.boostSelections),
                boostWinningPlan: {
                    // actual game boost code
                    code: game?.pools[game.type]?.result?.boostInfo?.boostCode,
                    winnings: bet.boostWinningPlan,
                },
                boostPayment: bet.boostDigitDividend && {
                    amount: bet.boostDigitDividend,
                },
            },
            addOns: bet.boost && ["boost"],
            system:
                bet.betType === "raket"
                    ? transformRaketSystemToOldFormat(bet.raketSystem)
                    : undefined,
            refund: isSharedOrShopShared
                ? bet.systemPayout?.refundAmount
                : bet.ownerPayout?.refundAmount,
            hasNewBetHistory, // to avoid potentially unnecessary using selector to find out if the bet has newBetHistory, just adding a prop for that
        };
    }

    const response = yield call(BetAPI.fetchReceiptResult, betId);
    return response.data;
}

export function* correctBet(action: any): SagaIterator<void> {
    const {
        payload: {betId},
    } = action;
    try {
        // @ts-expect-error
        const bet = yield* receiveReceiptResult(betId);

        yield put(receiveCorrectedBet(betId, bet));
    } catch (error: unknown) {
        log.error("Correct Bet error", {error: serializeError(error)});
        yield put(receiveCorrectedBetError(betId, error));
    }
}

export function* getBetByCode(action: any): SagaIterator<void> {
    const {
        payload: {betCode},
    } = action;

    yield put(BetActions.requestBetByCode(betCode));
    try {
        const response = yield call(BetAPI.fetchBetByCode, betCode);
        yield put(BetActions.receiveBetByCode(betCode, response));
    } catch (error: unknown) {
        // @ts-expect-error
        yield put(BetActions.receiveBetByCodeError(betCode, error));
    }
}

export default function* betSaga(): SagaIterator<void> {
    yield takeEvery(betActionTypes.REQUEST_CORRECTED_BET, correctBet);
    yield takeEvery(betActionTypes.GET_BET_BY_CODE, getBetByCode);
}
