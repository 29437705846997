import {trackVariable} from "@atg-shared/analytics";
import {type StaticStartlist} from "@atg-horse-shared/startlist-table-types";
import {formatStartlistName} from "../utils/format-startlist-name";
import {
    BET_DISTRIBUTION_DECIMALS_OFF,
    BET_DISTRIBUTION_DECIMALS_ON,
    COMPACT_VIEW_OFF,
    COMPACT_VIEW_ON,
    RACE_COMMENTS_OFF,
    RACE_COMMENTS_ON,
    VERTICAL_STARTLISTS_OFF,
    VERTICAL_STARTLISTS_ON,
} from "../constants/customization-constants";

type TrackStartlistCustomizationProps = {
    currentStartlist: StaticStartlist | undefined;
    isVerticalStartlistsActive: boolean;
    showRaceComments: boolean;
    showCompactStartlist: boolean;
    showBetDistributionDecimals: boolean;
};

export const trackStartlistCustomization = ({
    currentStartlist,
    isVerticalStartlistsActive,
    showRaceComments,
    showCompactStartlist,
    showBetDistributionDecimals,
}: TrackStartlistCustomizationProps) => {
    if (currentStartlist) {
        const preparedListName = formatStartlistName(currentStartlist.name);
        const userSelectedGlobalOptions = [
            isVerticalStartlistsActive ? VERTICAL_STARTLISTS_ON : VERTICAL_STARTLISTS_OFF,
            showRaceComments ? RACE_COMMENTS_ON : RACE_COMMENTS_OFF,
            showCompactStartlist ? COMPACT_VIEW_ON : COMPACT_VIEW_OFF,
            showBetDistributionDecimals
                ? BET_DISTRIBUTION_DECIMALS_ON
                : BET_DISTRIBUTION_DECIMALS_OFF,
        ];

        const userSelectedStartlistOptions = [
            `startlistInitialPreparedList ${preparedListName}`,
            ...currentStartlist.selectedSettings.startlist,
        ].sort();

        trackVariable({
            userSelectedGlobalOptions,
            userSelectedStartlistOptions,
        });
    }
};
