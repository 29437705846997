import {find, keys, reduce, isPlainObject, map} from "lodash";
import dayjs from "dayjs";
import {type GameType, GameTypes} from "@atg-horse-shared/game-types";
import {serverDate} from "@atg-shared/server-time";
import type {CalendarAPITypes, GameAPITypes} from "@atg-horse-shared/racing-info-api";
import {parseGameId} from "@atg-horse-shared/utils/gameid";
import {fetchSelectors, createInitialState} from "@atg-shared/fetch-redux";
import {type FetchState} from "@atg-shared/fetch-types";
import {parseRaceId} from "@atg-horse-shared/utils/game";
import {gameDefs} from "@atg-horse-shared/game-defs";
import {getBettableOrFirstRace} from "@atg-horse-shared/utils/track";
import * as UrlBuilder from "./urlBuilder";
import {findRaceById, type CalendarDay} from "./calendar";
import type {CalendarDayState, SportCalendarState} from "./calendarReducer";
import type {State} from ".";

const getCalendarState = (state: State, date: string): FetchState<CalendarDayState> =>
    state.calendar?.[date] || createInitialState({day: null});

export const getSportCalendarState = (state: State): SportCalendarState =>
    state.sportCalendar;

export const isCalendarLoading = (state: State, date: string): boolean =>
    fetchSelectors.isLoading(getCalendarState(state, date));
export const isCalendarLoaded = (state: State, date: string): boolean =>
    fetchSelectors.isLoaded(getCalendarState(state, date));
export const hasCalendarError = (state: State, date: string): boolean =>
    fetchSelectors.hasError(getCalendarState(state, date));

export const isSportCalendarLoading = (state: State): boolean =>
    fetchSelectors.isLoading(getSportCalendarState(state));
export const isSportCalendarLoaded = (state: State): boolean =>
    fetchSelectors.isLoaded(getSportCalendarState(state));
export const hasSportCalendarError = (state: State): boolean =>
    fetchSelectors.hasError(getSportCalendarState(state));
export const getSportCalendarLoadingStatus = (state: State) =>
    fetchSelectors.getLoadingStatus(getSportCalendarState(state));

export const getCalendarDay = (state: State, date: string): CalendarDay | null =>
    getCalendarState(state, date)?.day;

export const getCalendarDayVersion = (state: State, date: string): number => {
    const day = getCalendarDay(state, date);
    if (!day) return 0;

    return day.version || 0;
};

/**
 * Get calendar for today
 */
export const getCalendarForToday = (state: State): CalendarDay | null => {
    const date = serverDate();
    return getCalendarState(state, date)?.day;
};

/**
 * Get all the tracks in calender for today
 */
export const getTodaysTracks = (state: State): Array<GameAPITypes.CalendarTrack> => {
    const date = serverDate();
    const day = getCalendarDay(state, date);
    if (!day?.tracks) return [];
    return day.tracks;
};

export const getGamesByDay = (
    state: State,
    date: string,
): {[key in GameType]?: Array<CalendarAPITypes.CalendarGame>} | null => {
    const day = getCalendarDay(state, date);

    if (!day) return null;

    return day.games;
};

export const getGamesByDayAndGameType = (
    state: State,
    date: string,
    gameType: GameType,
): Array<CalendarAPITypes.CalendarGame> | null | undefined => {
    const games = getGamesByDay(state, date);
    if (!games) return null;

    return games[gameType];
};

export const getRace = (
    state: State,
    raceId?: string,
): CalendarAPITypes.CalendarRace | undefined | null => {
    if (!raceId) return null;
    const {date} = parseRaceId(raceId);
    const day = getCalendarDay(state, date);

    if (!day) return null;

    return findRaceById(day, raceId);
};

export const getRaceNumber = (state: State, raceId: string): number => {
    const race = getRace(state, raceId);
    if (!race) return 0;

    return race.number;
};

export const getCalendarGameById = (
    state: State,
    id: string,
): CalendarAPITypes.CalendarGame | undefined | null => {
    const {date, gameType} = parseGameId(id) || {};

    const day = getCalendarDay(state, date || "");
    if (!day) return null;

    return find(day.games[gameType as GameType], {id});
};

// Url building selectors below:
export const getRaceLink = (state: State, raceId: string, gameType: GameType): string => {
    const {date} = parseRaceId(raceId);
    const day = getCalendarDay(state, date);

    if (!day) return "";

    return UrlBuilder.getRaceUrlFromDay(day, raceId, gameType);
};

export const getHighlightLink = (
    state: State,
    game?: CalendarAPITypes.CalendarGame,
): string => {
    if (!game || !game.scheduledStartTime || !game.id) return "";

    const date = dayjs(game.scheduledStartTime).format("YYYY-MM-DD");
    const day = getCalendarDay(state, date);

    if (!day) return "";
    return UrlBuilder.buildFriendlyDivisionGameUrl(day, game);
};

const getResultUrl = (url: string): string => `${url}/resultat`;

const getCalendarRaceUrl = (state: State, id: string, isResultLink: boolean) => {
    const baseUrl = getRaceLink(state, id, GameTypes.vinnare);
    if (!baseUrl) return "";
    return isResultLink ? getResultUrl(baseUrl) : baseUrl;
};

const getCalendarGameUrl = (
    state: State,
    game: CalendarAPITypes.CalendarGame,
    isResultLink: boolean,
) => {
    const baseUrl = isPlainObject(game.gameType)
        ? game.url
        : getHighlightLink(state, game);
    if (!baseUrl) return "";
    return isResultLink ? getResultUrl(baseUrl) : baseUrl;
};

export const getCalendarTrackUrl = (
    state: State,
    track: GameAPITypes.CalendarTrack,
    isResultLink: boolean,
): string => {
    const firstBettableRace = getBettableOrFirstRace(track);
    if (!firstBettableRace) return "";
    return getCalendarRaceUrl(state, firstBettableRace.id, isResultLink);
};

export const getHighlights = (
    state: State,
    track: GameAPITypes.CalendarTrack,
    useResultLinks: boolean,
) => {
    const date = dayjs(track.startTime).format("YYYY-MM-DD");
    const calendarDay = getCalendarDay(state, date);
    const gamesByType = calendarDay?.games;
    const gameKeys: Array<string> = keys(gamesByType || {});
    const gamesOfWantedType: Array<CalendarAPITypes.CalendarGame | undefined> | [] =
        gamesByType
            ? gameKeys.reduce(
                  (
                      games: Array<CalendarAPITypes.CalendarGame | undefined>,
                      gameType: string,
                  ) => games.concat(gamesByType[gameType as GameType]),
                  [],
              )
            : [];

    return reduce(
        gamesOfWantedType,
        (result: any, game: any) => {
            const isGameOnTrack =
                game.tracks && game.tracks.some((trackId: any) => trackId === track.id);

            if (!isGameOnTrack) return result;
            result.push({
                ...game,
                url: getCalendarGameUrl(state, game, useResultLinks),
            });
            return result;
        },
        [],
    );
};

export const getRacesWithUrl = (
    state: State,
    track: GameAPITypes.CalendarTrack,
    isResultLink: boolean,
) =>
    map(track.races, (race) => ({
        ...race,
        url: getCalendarRaceUrl(state, race.id, isResultLink),
    }));

export const getCurrentRaceData = (
    state: State,
    raceId: string | undefined,
    isResultLink: boolean,
) => {
    if (!raceId) return undefined;

    const url = getCalendarRaceUrl(state, raceId, isResultLink);
    const number = getRaceNumber(state, raceId);
    if (!url || !number) return undefined;
    return {url, number};
};
