import {
    DETAILED_LIST,
    NEW_LIST,
    POPULAR_LIST,
    SIMPLE_LIST,
} from "../constants/customization-constants";

export const formatStartlistName = (currentStartlistName: string) => {
    switch (currentStartlistName) {
        case "enkel lista":
            return SIMPLE_LIST;
        case "populär":
            return POPULAR_LIST;
        case "detaljerad":
            return DETAILED_LIST;
        default:
            return NEW_LIST;
    }
};
