import {t} from "@lingui/macro";
import {RgsLimitTypeEnum} from "./enums";
import type {LimitPeriod, LimitRowVariants} from "./types";

// Limit increase request is disabled. Read https://atgse.atlassian.net/browse/RGKYC-2046 for more information.
export const ENABLE_LIMIT_INCREASE_REQUEST = false;

export const DEBOUNCE_DELAY_IN_MS = 750;

export const netLossLimitContext = {limitType: RgsLimitTypeEnum.LOSS};

function getCaption(limitPeriod: LimitPeriod): string {
    switch (limitPeriod) {
        case "dailyLimit":
            return t`Dagsgräns`;
        case "weeklyLimit":
            return t`Veckogräns`;
        case "monthlyLimit":
            return t`Månadsgräns`;
        default:
            return "";
    }
}

export const limitRowVariants: LimitRowVariants = {
    day: {
        key: "dailyLimit",
        caption: getCaption("dailyLimit"),
    },
    week: {
        key: "weeklyLimit",
        caption: getCaption("weeklyLimit"),
    },
    month: {
        key: "monthlyLimit",
        caption: getCaption("monthlyLimit"),
    },
};
