import {combineReducers} from "redux";
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import type {MediaObject} from "@atg-play-shared/media-graphql-client/__generated__/types.generated";
import type {GameType} from "@atg-horse-shared/game-types";
import {LOGOUT_FINISHED} from "@atg-global-shared/user/userActionTypes";
import * as PlayerReducer from "../player/playerReducer";
import {
    EXPAND_VIDEO_FRAME,
    TOGGLE_VIDEO_FRAME_EXPANDED,
    SET_VIDEO_ONLY,
    MEDIA_WARMUP,
    MEDIA_ARCHIVE,
    OPEN_VIDEO_POPUP,
    CLOSE_VIDEO_POPUP,
} from "./videoActionConstants";

export type VideoFrameState = {
    videoOnly: boolean;
    videoFrameExpanded: boolean;
};

// Make warmupPlayerVideoState exact https://jira-atg.riada.cloud/browse/LIVE-34
export type WarmupPlayerVideoState = {
    type?: string;
    mediaId?: any;
    mediaIds?: [{mediaId: string; timestamp: string}];
    graphicsMedia?: MediaObject;
    gameId?: string;
    gameType?: GameType;
    track?: any;
    raceNumber?: number;
    raceName?: string;
    date?: string;
    startId?: string;
    horseName?: string;
    startNumber?: string;
    countryCode?: string;
};

export type VideoState = {
    videoFrame: VideoFrameState;
    warmupVideo: WarmupPlayerVideoState;
    players: {
        live: PlayerReducer.VideoPlayerState;
        warmupPlayer: PlayerReducer.VideoPlayerState;
    };
};

export function warmupVideo(state: WarmupPlayerVideoState = {}, action: any) {
    const {type, payload} = action;

    switch (type) {
        case MEDIA_WARMUP: {
            const {
                mediaIds,
                gameId,
                gameType,
                track,
                countryCode,
                raceNumber,
                raceName,
                date,
                startId,
                horseName,
                startNumber,
            } = payload;

            return {
                ...state,
                type: "warmup",
                mediaId: undefined,
                mediaIds,
                gameId,
                gameType,
                track,
                countryCode,
                raceNumber,
                raceName,
                date,
                startId,
                horseName,
                startNumber,
            };
        }
        case MEDIA_ARCHIVE: {
            const {
                mediaId,
                graphicsMedia,
                gameId,
                gameType,
                track,
                raceNumber,
                raceName,
                date,
                countryCode,
            } = payload;
            return {
                ...state,
                type: "archive",
                mediaId,
                mediaIds: undefined,
                graphicsMedia, // stored in state only for archiveVideoPopoutOptions to use
                gameId,
                gameType,
                track,
                raceNumber,
                raceName,
                date,
                startId: undefined,
                horseName: undefined,
                startNumber: undefined,
                countryCode,
            };
        }
        case OPEN_VIDEO_POPUP:
            return {
                ...state,
                [payload]: true,
            };
        case CLOSE_VIDEO_POPUP:
            return {
                ...state,
                [payload]: false,
            };
        case LOGOUT_FINISHED:
        default:
            return state;
    }
}

export const initialStateVideoFrame = () => ({
    videoOnly: false,
    videoFrameExpanded: false,
});

export function videoFrame(state: any = initialStateVideoFrame(), action: any) {
    const {type, payload} = action;

    switch (type) {
        case EXPAND_VIDEO_FRAME:
            return {
                ...state,
                videoOnly: false,
                videoFrameExpanded: payload.expanded,
            };
        case TOGGLE_VIDEO_FRAME_EXPANDED:
            return {
                ...state,
                videoFrameExpanded: !state.videoFrameExpanded,
            };
        case SET_VIDEO_ONLY:
            return {
                ...state,
                videoOnly: payload.isVideoOnly,
            };
        default:
            return state;
    }
}

const livePlayerPersistConfig = {
    key: "horseLiveVideoPlayer",
    storage,
    whitelist: ["volume"],
};

const mediaPlayerPersistConfig = {
    key: "horseMediaVideoPlayer",
    storage,
    whitelist: ["volume"],
};

// @ts-expect-error
export default combineReducers<_, VideoState>({
    videoFrame,
    warmupVideo,
    players: combineReducers({
        live: persistReducer(
            livePlayerPersistConfig,
            PlayerReducer.default(PlayerReducer.PlayerKeys.LIVE_PLAYER),
        ),
        warmupPlayer: persistReducer(
            mediaPlayerPersistConfig,
            PlayerReducer.default(PlayerReducer.PlayerKeys.WARMUP_PLAYER),
        ),
    }),
});
