import dayjs from "dayjs";
import {now, parseDateTimestamp} from "@atg-shared/datetime";
import {filter, some, flow, sortBy, get, find, map, compact} from "lodash/fp";
import type {CalendarAPITypes} from "@atg-horse-shared/racing-info-api";
import {serverTime} from "@atg-shared/server-time";
import * as Track from "@atg-horse-shared/utils/track";
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as Calendar from "@atg-horse-shared/calendar/domain/calendar";
import type {Channel, ChannelNextRace} from "../video/video";

/**
 *  Check if given `CalendarGame` exist for the given track id
 */
const checkIfTrackHasGame = (
    trackId: number,
    calendarGame: Array<Calendar.CalendarGame>,
) => some((game) => some((id) => trackId === id, game.tracks), calendarGame);

/**
 * Sort base on if track has any `DD` or `LD` starts,
 */
const sortTrackByDDorLd =
    (day: Calendar.CalendarDay) => (tracks: Array<CalendarAPITypes.CalendarTrack>) => {
        const {dd, ld} = day.games;
        return sortBy((track) => {
            const hasDdOrLd =
                checkIfTrackHasGame(track.id, dd) || checkIfTrackHasGame(track.id, ld);
            return hasDdOrLd ? 1 : 2;
        }, tracks);
    };

/**
 * Check if status upcoming and ongoing is not there in any of the
 * tracks races
 * @param {*} tracks
 */
export const removePassedTracks = (tracks: Array<CalendarAPITypes.CalendarTrack>) =>
    tracks.filter((track) =>
        track?.races?.some(
            (race) => race.status === "upcoming" || race.status === "ongoing",
        ),
    );

/**
 * Returns the closes race to start or if it is ongoing in a `CalendarTrack`
 * The `CalendarTrack` check the closes race with the property `nextRace`.
 * Sometimes that property is not set, in that case the function returns
 * the first race with the status `ongoing` or `upcoming`
 * @param {*} day
 * @param {*} track
 */
export const getClosestRace = (
    track: CalendarAPITypes.CalendarTrack | null | undefined,
) => {
    const upcomingRace = find(
        (race) =>
            race.status === Calendar.GetRaceStatus.upcoming ||
            race.status === Calendar.GetRaceStatus.ongoing,
        track?.races || [],
    );

    if (upcomingRace) return upcomingRace;

    return null;
};

/**
 * Get closest race based on status of (Star) which is moved after DEF,
 * the def is set in the betting system when the race wont restart and betting is total close.
 * `nextRace` is then changed and will not be bettable for the users.
 * `nextRace` is not always setted in that case the closestRace is used.
 * See: https://confluence-atg.riada.cloud/pages/viewpage.action?spaceKey=HDDev&title=Livebar+2.0+-+Track+and+stream+logic
 * @param {*} track
 */
export const getClosestRaceByNextRace = (
    track?: CalendarAPITypes.CalendarTrack,
    raceId?: string,
): CalendarAPITypes.CalendarRace | null | undefined =>
    // @ts-expect-error
    raceId ? Track.findRaceById(track, raceId) : getClosestRace(track);

export const getClosestRaceFromCalendarTracks = (
    raceId: string,
    tracks: Array<CalendarAPITypes.CalendarTrack>,
) =>
    flow(
        map<
            CalendarAPITypes.CalendarTrack,
            CalendarAPITypes.CalendarRace | null | undefined
        >((track) => getClosestRaceByNextRace(track, raceId)),
        compact,
        find((race) => race.id === raceId),
    )(tracks);

/**
 * Ongoing is set whenever the nextRace is within one hour
 */
const getOnGoingTracks = (tracks: Array<CalendarAPITypes.CalendarTrack>) =>
    filter((track) => {
        const closestRace = getClosestRace(track);
        if (!closestRace) {
            throw Error("getOnGoingTracks: no closest race found");
        }
        return dayjs(closestRace.startTime).diff(serverTime(), "hours") < 1;
    }, tracks);

/**
 * Upcoming races are set when it starts in more than one hour
 * @param {*} tracks
 */
const getUpcomingTracks = (tracks: Array<CalendarAPITypes.CalendarTrack>) =>
    filter((track) => {
        const closestRace = getClosestRace(track);
        if (!closestRace) {
            throw Error("getUpcomingTracks: no closest race found");
        }
        return dayjs(closestRace.startTime).diff(serverTime(), "hours") >= 1;
    }, tracks);

/**
 * Return `CalendarTrack` closes race time
 * @param {*} tracks
 */
const sortByClosestRaceStartTime = (tracks: Array<CalendarAPITypes.CalendarTrack>) =>
    sortBy<CalendarAPITypes.CalendarTrack>((track) => {
        const closestRace = getClosestRace(track);
        if (!closestRace) {
            throw Error("sortByClosestRaceStartTime: no closestRace found");
        }
        return closestRace.startTime;
    }, tracks);

/**
 * Sort the given country code first
 * @param {CountryCode} country string
 */
const sortByCountryCode =
    (country: string) =>
    (
        tracks: Array<CalendarAPITypes.CalendarTrack>,
    ): Array<CalendarAPITypes.CalendarTrack> =>
        sortBy(({countryCode}) => (countryCode === country ? 1 : 2), tracks);

const sortByTrackId = (
    tracks: Array<CalendarAPITypes.CalendarTrack>,
): Array<CalendarAPITypes.CalendarTrack> => sortBy(get("id"), tracks);

/**
 * Sorting rules for ongoing track
 *
 * @rule 5 - 1 where 5 is the lowest prio in the sorting and 1 is the highest
 * @fifth `Ongoing` when first race start in less than an hour
 * @fourth Track code lowest first
 * @third Closes race before start
 * @second Domestic track before Foreign track
 * @first DD or LD should always be prio
 */
export const sortOngoingTracks = (
    day: Calendar.CalendarDay,
    tracks: Array<CalendarAPITypes.CalendarTrack>,
): Array<CalendarAPITypes.CalendarTrack> =>
    flow(
        getOnGoingTracks,
        sortByTrackId,
        sortByClosestRaceStartTime,
        sortByCountryCode("SE"),
        sortTrackByDDorLd(day),
    )(tracks);

/**
 * Sorting rules for upcoming track
 *
 * @rule
 * @first Track is `upcoming` when first race starts after more than an hour
 * @second Sorted by `startTime`
 */
export const sortUpComingTracks = (
    tracks: Array<CalendarAPITypes.CalendarTrack>,
): Array<CalendarAPITypes.CalendarTrack> =>
    flow(getUpcomingTracks, sortByClosestRaceStartTime)(tracks);

/**
 * Return closest upcoming or ongoing `CalendarTrack and its `CalendarRace`
 * and nextRace if set.
 * The nextRace is set whenever the `racinginfo/calendar/day` get a star mark which is when the race
 * has finished. It is a middle state between ongoing and result with the purpose of changing the track
 * before result as been set.
 * Filter out special campaign tracks like Elitloppet(47) and Sprintermästaren(47)
 * because we don't want them in the "Följ sändningens startlista"
 * @param {*} tracks
 */
const omittedTrack = 47;
export const getClosestTrackWithRace = (
    tracks: Array<CalendarAPITypes.CalendarTrack>,
): [
    CalendarAPITypes.CalendarTrack | null | undefined,
    CalendarAPITypes.CalendarRace | null | undefined,
    CalendarAPITypes.CalendarRace | null | undefined,
] => {
    const permittedTracks = filter((track) => track.id !== omittedTrack, tracks);
    // check the nextRace in all tracks then sort it by startTime,
    const nextTracksWithNextRace = flow(
        map<
            CalendarAPITypes.CalendarTrack,
            CalendarAPITypes.CalendarRace | null | undefined
        >((track) => getClosestRaceByNextRace(track, track?.nextRace)),
        compact,
        sortBy(get("startTime")),
    )(permittedTracks);

    const nextTracks = sortBy(
        (track) => getClosestRace(track)?.startTime,
        permittedTracks,
    );
    const nextTrack: CalendarAPITypes.CalendarTrack | null | undefined = nextTracks[0];

    const nextRace: CalendarAPITypes.CalendarRace | null | undefined = nextTrack
        ? getClosestRace(nextTrack)
        : undefined;
    return [nextTrack, nextRace, nextTracksWithNextRace[0]];
};

/**
 * Get tracks of today for given `Array<CalendarTrack>`
 * The tracks are fetched from selectedChannel and entered as a param here.
 */
export const getTracksForToday = (
    day: Calendar.CalendarDay | null | undefined,
    tracks: Array<CalendarAPITypes.CalendarTrack> | null,
): Array<CalendarAPITypes.CalendarTrack> => {
    if (!day || !tracks) return [];
    return compact(
        // @ts-expect-error
        map<CalendarTrack, CalendarTrack>(
            (channelTrack) => Calendar.findTrackById(day, channelTrack.id),
            tracks,
        ),
    );
};

/**
 * Return correct track based on given `raceId`
 */
export const findTrackByRaceId = (
    raceId: string,
    tracks: Array<CalendarAPITypes.CalendarTrack>,
) => find((track) => find((race) => race.id === raceId, track.races || []), tracks);

/**
 * Return correct track based on given `trackId`
 */
export const findTrackByTrackId = (
    trackId: number,
    tracks: Array<CalendarAPITypes.CalendarTrack>,
) => find((track) => track.id === trackId, tracks);

/**
 * This function converts the `ChannelTrack` to a normal `CalendarTrack`
 * @note That this should be removed when the `ChannelTrack` property is removed
 * and should not be used, the information can be find inside the `CalendarTrack`
 *
 * @see
 * jira -> https://jira-atg.riada.cloud/browse/LIVE-62
 */
export const convertChannelTrackToCalendarTrack = (
    channelTrack: any,
    calendarTracks: Array<CalendarAPITypes.CalendarTrack>,
) =>
    calendarTracks.filter(({name}) =>
        channelTrack?.find(
            (track: CalendarAPITypes.CalendarTrack) => track.name === name,
        ),
    );

/**
 * Returns time as minutes if the givenTime has past 1 hour
 * otherwise it returns `HH:mm`
 */
export const getDisplayTime = (
    givenTime: dayjs.Dayjs,
    startTime: string | null,
): string => {
    const startDayjs = parseDateTimestamp(startTime || "");
    const timeLeft = startDayjs.diff(givenTime, "minutes");
    if (timeLeft > 60) return startDayjs.format("HH:mm");
    if (timeLeft > 0) return `${timeLeft} min`;
    return "--";
};

/**
 * Compare servertime with channelTime and returns
 * `Avslutade` if endTime is passed otherwise a string with the `startTime`
 * as `Lopp [raceNumber]  • [channelStartTime]`
 */
export const getChannelStatus = (
    race: CalendarAPITypes.CalendarRace,
    channel: Channel,
) => {
    const startTime = parseDateTimestamp(channel.startTime);
    const endTime = parseDateTimestamp(channel.endTime);

    if (now().isBefore(startTime)) {
        return `Lopp ${race.number} • ${getDisplayTime(now(), channel.startTime)}`;
    }
    if (now().isAfter(endTime)) {
        return "Avslutad";
    }
    return null;
};

/**
 * Return `Just nu` or `Just nu [raceTime] as minutes`
 * if start contains minutes until start
 * @param {Current race from channel} race
 */
export const getCurrentRaceTime = (race: ChannelNextRace) => {
    const startTime = dayjs(race.startTime);
    const timeTillStart = startTime.diff(serverTime(), "minutes");
    return timeTillStart > 0
        ? `Lopp ${race.number} • ${getDisplayTime(serverTime(), race.startTime)}`
        : "Just nu";
};

export const getNextRaceTime = (race: ChannelNextRace) => {
    let nextRaceTime;
    if (race.currentRace) {
        const startTime = parseDateTimestamp(race.startTime);
        const timeTillStart = startTime.diff(serverTime(), "minutes");
        nextRaceTime =
            timeTillStart >= 0
                ? `Lopp ${race.number} • ${getDisplayTime(now(), race.startTime)}`
                : "-- min";
    } else {
        nextRaceTime = `Lopp ${race.number} • ${getDisplayTime(
            serverTime(),
            race.startTime,
        )}`;
    }
    return nextRaceTime;
};

/**
 * Returns status based on given channels `currentRace` `nextRace` and `upcoming`
 */
export const getStreamStatus = (
    race: CalendarAPITypes.CalendarRace,
    channel: Channel,
) => {
    const {currentRace, nextRace} = channel;

    if (!channel.startTime) return null;

    if (currentRace) {
        return getCurrentRaceTime(currentRace);
    }
    if (nextRace) {
        return getNextRaceTime(nextRace);
    }
    return getChannelStatus(race, channel);
};
