import {type GameType} from "@atg-horse-shared/game-types";
import type {Brackets} from "./harryBoyFeeReducer";

export const FETCH_HARRY_BOY_FEE_BRACKETS = "coupon/fetchHarryBoyFeeBrackets" as const;
export const RECEIVE_HARRY_BOY_FEE_BRACKETS =
    "coupon/receiveHarryBoyFeeBrackets" as const;

export type FetchHarryBoyFeeBracketsAction = {
    type: typeof FETCH_HARRY_BOY_FEE_BRACKETS;
    payload: {gameType?: GameType};
};

type ReceiveHarryBoyFeeBracketsAction = {
    type: typeof RECEIVE_HARRY_BOY_FEE_BRACKETS;
    payload: Brackets;
    error?: boolean;
};

export type HarryBoyFeeBracketsAction =
    | FetchHarryBoyFeeBracketsAction
    | ReceiveHarryBoyFeeBracketsAction;

export const fetchHarryBoyFeeBrackets = (
    gameType?: GameType,
): FetchHarryBoyFeeBracketsAction => ({
    type: FETCH_HARRY_BOY_FEE_BRACKETS,
    payload: {gameType},
});

export const receiveHarryBoyFeeBrackets = (
    payload: Brackets,
    error?: boolean,
): ReceiveHarryBoyFeeBracketsAction => ({
    type: RECEIVE_HARRY_BOY_FEE_BRACKETS,
    payload,
    error,
});
