import {find} from "lodash/fp";
import {type GameType, type Big9, GameTypes} from "@atg-horse-shared/game-types";
import type {GameAPITypes, CalendarAPITypes} from "@atg-horse-shared/racing-info-api";

export const TOP7_PRO = "top7pro";
export const DIVISION_GAME_URL = "DIVISION_GAME_URL";
export const SINGLE_RACE_GAME_URL = "SINGLE_RACE_GAME_URL";
export const RAKET_GAME_URL = "RAKET_GAME_URL";
export const TOP7_GAME_URL = "TOP7_GAME_URL";

function getGameType(
    game: GameAPITypes.Game | GameAPITypes.GameInfo | CalendarAPITypes.CalendarGame,
): GameType {
    return (
        (game as GameAPITypes.Game).type ||
        (typeof (game as GameAPITypes.GameInfo | CalendarAPITypes.CalendarGame)
            .gameType === "string" &&
            (game as GameAPITypes.GameInfo | CalendarAPITypes.CalendarGame).gameType) ||
        (game as GameAPITypes.GameInfo).gameType.type
    );
}

function getShortNameFromGameType(gameType: GameType | Big9 | typeof TOP7_PRO): string {
    const gameDef = gameDefs[gameType as GameType];
    return gameDef.shortName || gameDef.name;
}

function divisionGameRaceName(game: any, race: any) {
    const gameRace = find({id: race.id}, game.races);
    return `${getShortNameFromGameType(getGameType(game))}-${
        game.races.indexOf(gameRace) + 1
    }`;
}

function singleRaceGameRaceName(game: any) {
    return getShortNameFromGameType(getGameType(game));
}

export type GameDef = {
    /** user-friendly presentation of a game type, e.g. "Lunch Dubbel" instead of "ld" */
    name: string;

    /** short user-friendly presentation of a game type, e.g. "LD" instead of "ld" */
    shortName?: string;

    /** shortest user-friendly presentation of a game type, e.g. "V" instead of "vinnare" */
    mobileName?: string;

    prenumerationName?: string;
    showRaceMenu?: boolean;
    raceName?: (game: GameAPITypes.Game, race: GameAPITypes.GameRace) => string;
    urlType?: string;
    url?: string;
    numberOfRaces?: number;
    mergedPoolsShortName?: string;
    correctsToWin?: Array<number>;
};

/**
 * Note that TOP7_PRO is not a real `GameType`, it's purely a frontend construct.
 */
export const gameDefs: {
    [gameType in GameType | typeof TOP7_PRO | "harryboy" | "galopp"]: GameDef;
} = {
    [GameTypes.V75]: {
        name: "V75",
        prenumerationName: "V75 Harry Boy",
        raceName: divisionGameRaceName,
        urlType: DIVISION_GAME_URL,
        numberOfRaces: 7,
        correctsToWin: [5, 6, 7],
    },
    [GameTypes.V86]: {
        name: "V86",
        prenumerationName: "V86 Harry Boy",
        raceName: divisionGameRaceName,
        urlType: DIVISION_GAME_URL,
        numberOfRaces: 8,
        correctsToWin: [6, 7, 8],
    },
    [GameTypes.GS75]: {
        name: "Grand Slam 75",
        prenumerationName: "GS75 Harry Boy",
        shortName: "GS75",
        raceName: divisionGameRaceName,
        numberOfRaces: 7,
        correctsToWin: [5, 6, 7],
    },
    [GameTypes.V64]: {
        name: "V64",
        prenumerationName: "V64 Harry Boy",
        raceName: divisionGameRaceName,
        urlType: DIVISION_GAME_URL,
        numberOfRaces: 6,
        correctsToWin: [4, 5, 6],
    },
    [GameTypes.V65]: {
        name: "V65",
        prenumerationName: "V65 Harry Boy",
        raceName: divisionGameRaceName,
        urlType: DIVISION_GAME_URL,
        numberOfRaces: 6,
        correctsToWin: [5, 6],
    },
    [GameTypes.V5]: {
        name: "V5",
        prenumerationName: "V5 Harry Boy",
        raceName: divisionGameRaceName,
        urlType: DIVISION_GAME_URL,
        numberOfRaces: 5,
        correctsToWin: [5],
    },
    [GameTypes.V4]: {
        name: "V4",
        prenumerationName: "V4 Harry Boy",
        raceName: divisionGameRaceName,
        urlType: DIVISION_GAME_URL,
        numberOfRaces: 4,
        correctsToWin: [4],
    },
    [GameTypes.V3Legacy]: {
        name: "V3",
        prenumerationName: "V3 Harry Boy",
        raceName: divisionGameRaceName,
        urlType: DIVISION_GAME_URL,
        numberOfRaces: 3,
        correctsToWin: [3],
    },
    [GameTypes.V3]: {
        name: "V3",
        prenumerationName: "V3 Harry Boy",
        raceName: divisionGameRaceName,
        urlType: DIVISION_GAME_URL,
        numberOfRaces: 3,
        correctsToWin: [3],
    },
    [GameTypes.raket]: {
        name: "Raket",
        raceName: singleRaceGameRaceName,
        urlType: RAKET_GAME_URL,
        numberOfRaces: 7,
    },
    [GameTypes.dd]: {
        name: "Dagens Dubbel",
        prenumerationName: "DD Harry Boy",
        shortName: "DD",
        raceName: divisionGameRaceName,
        urlType: DIVISION_GAME_URL,
        numberOfRaces: 2,
        correctsToWin: [2],
    },
    [GameTypes.ld]: {
        name: "Lunch Dubbel",
        prenumerationName: "LD Harry Boy",
        shortName: "LD",
        raceName: divisionGameRaceName,
        urlType: DIVISION_GAME_URL,
        numberOfRaces: 2,
        correctsToWin: [2],
    },
    [GameTypes.trio]: {
        name: "Trio",
        raceName: singleRaceGameRaceName,
        urlType: SINGLE_RACE_GAME_URL,
        url: SINGLE_RACE_GAME_URL,
        numberOfRaces: 1,
        mergedPoolsShortName: "Tr",
    },
    [GameTypes.tvilling]: {
        name: "Tvilling",
        raceName: singleRaceGameRaceName,
        urlType: SINGLE_RACE_GAME_URL,
        numberOfRaces: 1,
        mergedPoolsShortName: "Tv",
    },
    [GameTypes.komb]: {
        name: "Komb",
        raceName: singleRaceGameRaceName,
        urlType: SINGLE_RACE_GAME_URL,
        numberOfRaces: 1,
        mergedPoolsShortName: "K",
    },
    [GameTypes.vinnare]: {
        name: "Vinnare",
        mobileName: "V",
        raceName: singleRaceGameRaceName,
        urlType: SINGLE_RACE_GAME_URL,
        numberOfRaces: 1,
    },
    [GameTypes.plats]: {
        name: "Plats",
        mobileName: "P",
        raceName: singleRaceGameRaceName,
        urlType: SINGLE_RACE_GAME_URL,
        numberOfRaces: 1,
    },
    [GameTypes.vp]: {
        name: "V&P",
        raceName: singleRaceGameRaceName,
        urlType: SINGLE_RACE_GAME_URL,
        numberOfRaces: 1,
    },
    harryboy: {
        name: "Harry Boy",
    },
    [GameTypes.top7]: {
        name: "Top 7",
        prenumerationName: "Top 7",
        showRaceMenu: false,
        raceName: singleRaceGameRaceName,
        urlType: TOP7_GAME_URL,
        numberOfRaces: 1,
    },
    top7pro: {
        name: "Top 7",
        showRaceMenu: false,
        raceName: singleRaceGameRaceName,
        urlType: TOP7_GAME_URL,
        numberOfRaces: 1,
    },
    galopp: {
        name: "Galopp",
    },
};
