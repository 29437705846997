import {put, select, takeLatest} from "redux-saga/effects";
import {isEmpty} from "lodash/fp";
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as CalendarSelectors from "@atg-horse-shared/calendar/domain/calendarSelectors";
import * as Live from "../live/liveUtils";
import * as QuickplayActions from "./quickplayActions";
import * as QuickplaySelectors from "./quickplaySelectors";

/**
 * Saga that select the latest liveTrack,
 */
function* changeLiveTracks() {
    // @ts-expect-error
    const selectedRaceId = yield select(QuickplaySelectors.getSelectedRaceId);
    // @ts-expect-error
    const selectedGameType = yield select(QuickplaySelectors.getSelectedGameType);
    // @ts-expect-error
    const day = yield select(CalendarSelectors.getCalendarForToday);

    const tracks = day?.tracks;

    if (!tracks || isEmpty(tracks)) {
        return;
    }

    const [nextTrack, resultNextRace, nextRace] = Live.getClosestTrackWithRace(tracks);
    if (nextRace && nextRace.id !== selectedRaceId) {
        const availableGameTypes =
            QuickplaySelectors.selectAvailableSingleRaceGameTypesForGivenRaceId(
                day,
                nextRace?.id,
            );
        const isCurrentGameTypeAvailable = availableGameTypes?.includes(selectedGameType);
        const nextTrackWithNextRace = Live.findTrackByRaceId(nextRace.id, tracks);

        yield put(
            QuickplayActions.selectLiveTrack(
                nextRace.id,
                // @ts-expect-error
                nextTrackWithNextRace?.id,
                isCurrentGameTypeAvailable ? selectedGameType : "vinnare",
            ),
        );
        return;
    }
    if (!resultNextRace) return;

    if (resultNextRace.id !== selectedRaceId) {
        const availableGameTypes =
            QuickplaySelectors.selectAvailableSingleRaceGameTypesForGivenRaceId(
                day,
                resultNextRace?.id,
            );
        const isCurrentGameTypeAvailable = availableGameTypes?.includes(selectedGameType);

        yield put(
            QuickplayActions.selectLiveTrack(
                resultNextRace.id,
                nextTrack?.id,
                isCurrentGameTypeAvailable ? selectedGameType : "vinnare",
            ),
        );
    }
}

export default function* quickplaySaga() {
    yield takeLatest([QuickplayActions.SELECT_CURRENT_LIVE_TRACK], changeLiveTracks);
}
