import {find, isEmpty} from "lodash";
import {fetchSelectors} from "@atg-shared/fetch-redux";
// eslint-disable-next-line @nx/enforce-module-boundaries
import type {HorseState} from "@atg-horse-shared/horse-state";
import type {SharedBetReducer} from "./sharedBetReducer";
import type {SharedBetReceiptStep} from "./sharedBetReceiptSteps";
import type {
    SharedBetCreateParams,
    SharedBetPurchaseParams,
    SharedBetConditions,
} from "./sharedBet";
import type {Friend} from "./sharedBetInviteReducer";

export type StateProps = {
    sharedBet: SharedBetReducer;
    horse: HorseState;
};

export const getData = (
    state: StateProps,
): {
    [key: string]: any;
} => state.sharedBet;

export const getCreateParams = (state: StateProps): SharedBetCreateParams =>
    getData(state).create.params;

export const getPurchaseParams = (state: StateProps): SharedBetPurchaseParams =>
    getData(state).purchase.params;

export const getInviteError = (state: StateProps): string | null =>
    getData(state).invite.error;

export const getInviteParams = (state: StateProps): Array<Friend> =>
    getData(state).invite.params;

export const getCurrentReceiptStep = (state: StateProps): SharedBetReceiptStep =>
    getData(state).receipt.currentStep;

export const getConditions = (
    state: StateProps,
    id?: string,
): SharedBetConditions | null | undefined => {
    const conditions = getData(state).conditions.data;
    return find(conditions, {couponId: id});
};

export const hasSharedBetConditions = (state: StateProps, id: string): boolean =>
    !isEmpty(getConditions(state, id));

export const getSharedCost = (state: StateProps): number | null | undefined => {
    const {create} = getData(state);
    return create ? create.params.shareCost : null;
};

export const getCancelError = (state: StateProps): string | null =>
    getData(state).cancel.error;

export const getCancelLoadingStatus = (state: StateProps): boolean =>
    getData(state).cancel.loading;

export const inviteFriendsFlowStarted = (state: StateProps): boolean =>
    getData(state).invite.modalflow.started;

export const inviteFriendsFlowCouponId = (state: StateProps): string =>
    getData(state).invite.modalflow.couponId;

export const isNotFoundModalOpen = (state: StateProps): boolean =>
    getData(state).purchase.isNotFoundModalOpen;

export const isMaxSharesEditModalOpen = (state: StateProps): boolean =>
    getData(state).purchase.isMaxSharesEditModalOpen;

export const getTotalNrSharesState = (state: StateProps) =>
    state.sharedBet.conditions.editTotalNrShares;

export const totalNrSharesOpenModalConditions = (state: StateProps) =>
    state.sharedBet.conditions.totalNrSharesOpenModalConditions;

export const showSystemSizeLimit = (state: StateProps): boolean =>
    getData(state).create.showSystemSizeLimit;

export const {
    isLoading: totalNrSharesLoading,
    isLoaded: totalNrSharesLoaded,
    hasError: totalNrSharesError,
} = fetchSelectors.createSelectors<StateProps>(getTotalNrSharesState);
