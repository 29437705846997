import {combineReducers} from "redux";
import {storeApi} from "@atg-horse-shared/store-api";
import {reducer as realityCheckReducer} from "@atg-responsible-gambling-shared/reality-check-domain";
import guide from "@atg-horse/onboarding-guide/domain/guideReducer";
import product from "@atg-horse/product-pages/domain/productReducer";
import {VerticalsReducer} from "@atg-global-shared/verticals";
import {programChanges} from "@atg-horse-shared/calendar/domain/programChangesReducer";
import {
    startDetails,
    showStartDetailsFor,
} from "@atg-horse/more-info-modal/domain/startDetailsReducer";
import shop from "@atg-shop-shared/shops-domain-redux/src/shopReducer";
import {couponRulesReducer, couponRulesDecorator} from "@atg-horse-shared/coupon";
import * as CalendarReducers from "@atg-horse-shared/calendar/domain/calendarReducer";
import {accountSettingsReducer} from "@atg-payment-shared/account-settings-domain";
import startlistDisplayOptions from "@atg-horse/results-table-displayoptions/components/displayOptions/tableDisplayOptionsReducer";
import {harrySubscriptions} from "@atg-horse-shared/horse-harry/domain/harryReducer";
import {memberReducer} from "@atg-global-shared/member-data-access";
import cms from "@atg-global-shared/legacy-cms/domain/cmsReducer";
import {purchaseReducer} from "@atg-horse-shared/purchase";
import video from "@atg-horse-shared/video-domain/src/video/videoReducer";
import * as liveReducers from "@atg-horse-shared/video-domain/src/live/redux/livereducer";
import {navigationReducer} from "@atg-horse-shared/startlist-navigation";
import {limitsReducer as limits} from "@atg-responsible-gambling-shared/limits-domain";
import {elliReducer as elliAssistant} from "@atg-horse-shared/elli-assistant";
import {startListReducer as startlistSettings} from "@atg-horse-shared/startlist-store";
import {kycQuestionnaire} from "@atg-aml-shared/kyc-domain";
import {sharedBet} from "@atg-horse/shared-bet";
import champions from "@atg-horse/champions/domain/championsSlice";
import {mainNavbar} from "@atg-navigation-shared/main-navbar-data-access";
import toasts from "atg-ui-toast/domain/toastReducer";
import {modals, modalData} from "atg-modals/modalReducer";
import games from "atg-horse-game/domain/gameReducer";
import extendedStart from "atg-horse-game/domain/extendedStart/extendedStartReducer";
import selectedRace from "atg-horse-game/redux/selectedRaceReducer";
import {createHorseReducer} from "./domain/horseReducer";

const createRootReducer = (asyncReducers: any = {}) =>
    couponRulesReducer(
        combineReducers({
            [storeApi.reducerPath]: storeApi.reducer,
            ...asyncReducers.root,
            accountSettings: accountSettingsReducer,
            activeBreakpoint: () => window._frameStore.getState().activeBreakpoint,
            alert: () => window._frameStore.getState().alert,
            auth: () => window._frameStore.getState().auth,
            // see atg-micro-frontend/README.md
            accessToken: () => window._frameStore.getState().accessToken,
            calendar: CalendarReducers.calendar,
            sportCalendar: CalendarReducers.sportCalendar,
            cms,
            games,
            guide,
            kycQuestionnaire,
            horse: createHorseReducer(asyncReducers.horse),
            limits,
            ...liveReducers,
            mainMenu: () => window._frameStore.getState().mainMenu,
            member: memberReducer,
            memberRegister: () => window._frameStore.getState().memberRegister || null,
            modals,
            modalData,
            navigation: navigationReducer,
            extendedStart,
            product,
            programChanges,
            purchase: purchaseReducer,
            // TODO: remove when possible
            // at the time of writing still used for the popout video archive (includes LazyModal
            // which includes RealityCheck)
            realityCheck: realityCheckReducer,
            // see atg-micro-frontend/README.md
            router: () => window._frameStore.getState().router,
            selectedRace,
            sharedBet,
            shop,
            showStartDetailsFor,
            startDetails,
            startlistSettings,
            startlistDisplayOptions,
            toasts,
            // see atg-micro-frontend/README.md
            user: () => window._frameStore.getState().user,
            verticals: VerticalsReducer,
            video,
            harrySubscriptions,
            elliAssistant,
            champions,
            mainNavbar,
        }),
        couponRulesDecorator,
    );

export default createRootReducer;
