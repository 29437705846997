import {call, select, put, takeLeading} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import dayjs from "dayjs";
import {isEmpty} from "lodash/fp";
import type {MediaObject} from "@atg-play-shared/media-graphql-client/__generated__/types.generated";
import {serverTime} from "@atg-shared/server-time";
import {MEDIA_SERVICE_URL} from "@atg-shared/service-url";
import {OldStreamingApi} from "@atg-horse-shared/old-archiveraces-theplatform-api";
import * as VideoActionConstants from "../video/videoActionConstants";
import {PlayerKeys} from "./playerReducer";
import {PlayerActionTypes} from "./actionconstants";
import {getPlaylistPos} from "./playerSelectors";
import * as VideoSelectors from "./videoSelectors";
import * as PlayerActions from "./playerActions";

export function* resolveSourceFromBackend(source: unknown) {
    const {resolvePlatformURL, callBackendEndpoint, fetchGraphicsVideoUrlWithPublicUrl} =
        OldStreamingApi;

    let sourceResponse;
    let resolvedSource;
    try {
        // @ts-expect-error
        sourceResponse = yield call(callBackendEndpoint, source);
    } catch (err: unknown) {
        return null;
    }
    if (!sourceResponse) return null;

    // mpx/mediaId endpoint returns sourceResponse as an object
    // mpx2/graphcsVideo endpoint returns sourceResponse as string
    const stream = sourceResponse.streams
        ? sourceResponse.streams.find(
              (item: {type: string}) =>
                  item?.type === "application/x-mpegURL" || item.type === "video/mp4",
          )
        : sourceResponse;

    try {
        if (typeof stream === "string")
            resolvedSource = fetchGraphicsVideoUrlWithPublicUrl(stream);
        if (typeof stream === "object")
            // @ts-expect-error
            resolvedSource = yield call(resolvePlatformURL, stream.url, source);
    } catch (err: unknown) {
        return null;
    }

    return {
        resolvedSource,
        stream,
        metaData: sourceResponse,
    };
}

// @ts-expect-error
export function* resolveArchiveOrWarmupSource({player, source, playlist}) {
    // @ts-expect-error
    const sourceData = yield call(resolveSourceFromBackend, source);
    if (!sourceData) return;
    const {resolvedSource, metaData} = sourceData;
    const sourcePayload = {
        streamName: source,
        source: resolvedSource,
        playlist,
        imageURL: metaData.poster,
    };

    // @ts-expect-error
    yield put(PlayerActions.source(player, sourcePayload));
}

export function* resolveGraphicsMediaUrlFromMPX2({
    // @ts-expect-error
    player,
    // @ts-expect-error
    publicUrl,
    // @ts-expect-error
    imageURL,
    // @ts-expect-error
    streamName,
}) {
    // @ts-expect-error
    const streamUrlFromMPX2 = yield call(
        OldStreamingApi.fetchGraphicsVideoUrlWithPublicUrl,
        publicUrl,
    );
    const streamPayload = {
        streamName,
        source: streamUrlFromMPX2,
        imageURL,
    };
    // @ts-expect-error
    yield put(PlayerActions.source(player, streamPayload));
}

/**
 *
 * @param {*} param0
 */
export function* resolveMediaSource({payload}: {payload: unknown}) {
    // @ts-expect-error
    const mediaId = yield select(VideoSelectors.getMediaId);
    const mediaIds: Array<{mediaId: string; timestamp: string}> = yield select(
        VideoSelectors.getMediaIds,
    );

    if (!mediaId && !mediaIds) return;
    // @ts-expect-error
    const {graphicsMedia}: MediaObject = payload;

    // @ts-expect-error
    const mediaplayListPos = yield select(getPlaylistPos, PlayerKeys.WARMUP_PLAYER);

    const sortedMediaIds = [...mediaIds].sort(
        (mediaItem, mediaItem2) =>
            parseInt(mediaItem.timestamp, 10) - parseInt(mediaItem2.timestamp, 10),
    );

    const playlist = sortedMediaIds.map(
        (mediaItem) => `${MEDIA_SERVICE_URL}/${mediaItem.mediaId}`,
    );

    const media = !isEmpty(sortedMediaIds)
        ? sortedMediaIds?.[mediaplayListPos || 0]?.mediaId
        : mediaId;

    // hts/tv4 graphicsMedia has expiration date
    // (the clean feed we get from mediaId has no expiration date)
    const expirationDate = graphicsMedia && dayjs(graphicsMedia.expirationDate);
    const shouldUseGraphicsMedia =
        expirationDate && serverTime().isBefore(dayjs(expirationDate));

    if (shouldUseGraphicsMedia) {
        yield call(resolveGraphicsMediaUrlFromMPX2, {
            player: PlayerKeys.WARMUP_PLAYER,
            publicUrl: graphicsMedia.publicUrl,
            imageURL: graphicsMedia.defaultThumbnailUrl,
            streamName: graphicsMedia.title,
        });
    } else {
        yield call(resolveArchiveOrWarmupSource, {
            player: PlayerKeys.WARMUP_PLAYER,
            source: `${MEDIA_SERVICE_URL}/${media}`,
            playlist,
        });
    }
}

export function* resolveSourceFlow({payload}: {payload: {player: PlayerKeys}}) {
    const {player} = payload;

    if (player === PlayerKeys.WARMUP_PLAYER) {
        // @ts-expect-error
        yield call(resolveArchiveOrWarmupSource, payload);
    }
}

export default function* playerSaga(): SagaIterator {
    yield takeLeading(
        // @ts-expect-error
        [
            VideoActionConstants.MEDIA_ARCHIVE,
            VideoActionConstants.MEDIA_WARMUP,
            PlayerActionTypes.START_PLAYER_MEDIA_SOURCE,
            PlayerActionTypes.PLAYER_NEXT_TRACK,
            PlayerActionTypes.PLAYER_PREVIOUS_TRACK,
        ],
        resolveMediaSource,
    );
}
