import * as RacingInfoAPI from "@atg-horse-shared/racing-info-api";
import {FETCH, call} from "@atg-shared/fetch-redux";
import type {FetchAction, ReceiveAction} from "@atg-shared/fetch-types";
import * as CalendarSelectors from "./calendarSelectors";
import type {State} from ".";

/**
 * Make a request against `/services/racinginfo/v1/api/calendar/day/<day>`
 */
export const REQUEST_CALENDAR = "calendar/requestCalendar";

/**
 * Receive a response from `/services/racinginfo/v1/api/calendar/day/<day>`
 */
export const RECEIVE_CALENDAR = "calendar/receiveCalendar";

export const RESET_STATUS = "calendar/resetStatus";

export const START_LISTENING_TO_CALENDAR_PUSH = "calendar/startListeningToCalendarPush";
export const STOP_LISTENING_TO_CALENDAR_PUSH = "calendar/stopListeningToCalendarPush";
export const RECEIVE_CALENDAR_PUSH = "calendar/receiveCalendarPush";

/**
 * Make a request against `/services/racinginfo/v1/api/calendar/galopp`
 */
export const REQUEST_SPORT_CALENDAR = "calendar/requestSportCalendar";

/**
 * Receive a response from `/services/racinginfo/v1/api/calendar/galopp`
 */
export const RECEIVE_SPORT_CALENDAR = "calendar/receiveSportCalendar";

export const FIND_CALENDAR_FROM_GAME_TYPE = "calendar/findCalendarFromGameType";

type Context = {
    date: string;
};

export type JSONPatchData = {
    id: string;
    previousVersion: number;
    version: number;
    patch: Array<{
        op: string;
        path: string;
        value: any;
    }>;
};

export type CalendarFetchAction = FetchAction<
    typeof REQUEST_CALENDAR,
    typeof RECEIVE_CALENDAR,
    RacingInfoAPI.CalendarAPITypes.CalendarDayApiResponse,
    State,
    Context
>;

export type SportCalendarFetchAction = FetchAction<
    typeof REQUEST_SPORT_CALENDAR,
    typeof RECEIVE_SPORT_CALENDAR,
    RacingInfoAPI.CalendarAPITypes.GaloppCalendarResponse,
    State,
    Context
>;

export type CalendarPushAction = ReceiveAction<
    typeof RECEIVE_CALENDAR_PUSH,
    JSONPatchData,
    Context
>;

export type StartListeningToCalendarPushAction = {
    type: typeof START_LISTENING_TO_CALENDAR_PUSH;
    payload: {
        date: string;
    };
};

export type StopListeningToCalendarPushAction = {
    type: typeof STOP_LISTENING_TO_CALENDAR_PUSH;
    payload: {
        date: string;
        removeData: boolean;
    };
};

export type FindCalendarFromGameTypeAction = {
    type: typeof FIND_CALENDAR_FROM_GAME_TYPE;
    payload: {
        gameType: string;
    };
};

export type Action =
    | CalendarFetchAction
    | SportCalendarFetchAction
    | StartListeningToCalendarPushAction
    | StopListeningToCalendarPushAction
    | CalendarPushAction;

export const shouldLoadCalendar =
    (date: string, minVersion = 0, force = false) =>
    (state: State): boolean => {
        if (force) return true;

        const day = CalendarSelectors.getCalendarDay(state, date);
        if (day === null) return true;

        const version = CalendarSelectors.getCalendarDayVersion(state, date);
        return minVersion > version;
    };

/**
 * Fetch calendar day data
 *
 * API endpoint: `/services/racinginfo/v1/api/calendar/day/<day>`
 */
export const fetchCalendar = (
    date: string,
    minVersion?: number,
    force?: true,
): CalendarFetchAction => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_CALENDAR,
        receiveAction: RECEIVE_CALENDAR,
        shouldCallApi: call(shouldLoadCalendar, date, minVersion, force),
        callApi: call(RacingInfoAPI.fetchDay, date, minVersion),
        context: {date},
    },
});

export const startListeningToCalendarPush = (
    date: string,
): StartListeningToCalendarPushAction => ({
    type: START_LISTENING_TO_CALENDAR_PUSH,
    payload: {
        date,
    },
});

export const stopListeningToCalendarPush = (
    date: string,
    removeData = true,
): StopListeningToCalendarPushAction => ({
    type: STOP_LISTENING_TO_CALENDAR_PUSH,
    payload: {
        date,
        removeData,
    },
});

export const receiveCalendarPush = (
    date: string,
    data: JSONPatchData,
): CalendarPushAction => ({
    type: RECEIVE_CALENDAR_PUSH,
    __category: "fetch",
    error: false,
    payload: data,
    context: {date},
});

/**
 * Fetch galopp calendar data
 *
 * API endpoint: `/services/racinginfo/v1/api/calendar/galopp`
 */
export const fetchGaloppCalendar = (
    fromDate: string,
    toDate: string,
): SportCalendarFetchAction => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_SPORT_CALENDAR,
        receiveAction: RECEIVE_SPORT_CALENDAR,
        callApi: call(RacingInfoAPI.fetchGaloppCalendar, fromDate, toDate),
    },
});

export const findCalendarFromGameType = (
    gameType: string,
): FindCalendarFromGameTypeAction => ({
    type: FIND_CALENDAR_FROM_GAME_TYPE,
    payload: {gameType},
});
