export const EXPAND_VIDEO_FRAME = "EXPAND_VIDEO_FRAME";
export const TOGGLE_VIDEO_FRAME_EXPANDED = "TOGGLE_VIDEO_FRAME_EXPANDED";
export const TOGGLE_VIDEO_ONLY = "TOGGLE_VIDEO_ONLY";
export const SET_VIDEO_ONLY = "SET_VIDEO_ONLY";
export const SET_POPOUT_FRAME = "SET_POPOUT_FRAME";

export const MEDIA_WARMUP = "MEDIA_WARMUP";
export const MEDIA_WARMUP_WINDOW = "MEDIA_WARMUP_WINDOW";
export const MEDIA_ARCHIVE = "MEDIA_ARCHIVE";
export const MEDIA_ARCHIVE_START = "MEDIA_ARCHIVE_START";

export const MEDIA_ARCHIVE_WINDOW = "MEDIA_ARCHIVE_WINDOW";
export const OPEN_VIDEO_POPUP = "OPEN_VIDEO_POPUP";
export const CLOSE_VIDEO_POPUP = "CLOSE_VIDEO_POPUP";
export const CLOSE_VIDEO_POPUP_START = "CLOSE_VIDEO_POPUP_START";
