import {combineReducers} from "redux";
import {AuthActions} from "@atg-shared/auth";
// eslint-disable-next-line @nx/enforce-module-boundaries
import type {PlaceReducedBetResponse} from "@atg-horse-shared/purchase-varenne";
import {
    LegacyLoading,
    LegacyOK,
} from "@atg-shared/response-mapping/deprecated_loadingStatus";
import type {LoadingStatus} from "@atg-shared/response-mapping/deprecated_loadingStatus";
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as ReducedBetsActions from "@atg-horse/reduced-bets/src/domain/reducedBetsActions";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
    PLAYED_BET,
    PLAYED_BETS,
    PLACING_BET,
    PLACE_BET,
    STARTED_VARENNE_FLOW,
    VARENNE_BET_ERROR,
    VARENNE_BET_SUCCESS,
} from "@atg-horse-shared/horse-bet-types";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {SHARED_BET_PURCHASE_RESPONSE} from "@atg-horse/shared-bet";
import {
    START_PURCHASE_FLOW,
    SHOW_PURCHASE_RECEIPT,
    FINISH_PURCHASE_FLOW,
    CONFIRM_BET,
    RESTORE_PURCHASE_FLOW,
    HARRY_SUBSCRIPTION_PURCHASED,
    RESET_PURCHASE_IN_PROCESS,
    CHANGE_HARRY_FLAVOUR,
    DEPOSIT_STARTED,
    DEPOSIT_FINISHED,
    DEPOSIT_ABORTED,
} from "./purchaseActions";
import {CONFIRM, DEPOSIT, RECEIPT, INIT, VARENNE_PURCHASE, KYC} from "./purchaseSteps";
import type {Product} from "./products";
import type {PurchaseStep} from "./purchaseSteps";

export type PurchaseStepState = {
    id?: PurchaseStep;
    product?: Product;
    receipt?: {
        [key: string]: any;
    };
    reducedBet?: PlaceReducedBetResponse;
};

export type PurchaseState = {
    currentStep: PurchaseStepState;
    status: Record<string, unknown>;
};

const status = (state: LoadingStatus<any> = LegacyOK, action: any) => {
    const {type, payload, error} = action;

    switch (type) {
        case PLACE_BET:
            return {...state, purchaseInProcess: true};
        case PLACING_BET:
        case STARTED_VARENNE_FLOW:
        case ReducedBetsActions.PLACE_TILLSAMMANS_BET:
            return {...state, ...LegacyLoading};
        case HARRY_SUBSCRIPTION_PURCHASED:
        case PLAYED_BET:
        case PLAYED_BETS:
        case VARENNE_BET_SUCCESS:
        case SHARED_BET_PURCHASE_RESPONSE:
        case ReducedBetsActions.REDIRECTING_TILLSAMMANS_BET:
            // TODO: this is partially broken, `payload.status` can sometimes be a string which won't be handled correctly...
            return error ? payload.status : {...state, ...LegacyOK};
        case VARENNE_BET_ERROR:
            // on varenne bet error, stop the spinner. Errors are handled in horse-purchase-varenne/components/Confirmation/ConfirmationErrorHandler.tsx
            return {
                ...state,
                error: {
                    message: "",
                },
                isLoading: false,
                code: "failed",
            };
        case FINISH_PURCHASE_FLOW:
        case AuthActions.START_AUTHENTICATION_FLOW:
            return {...state, ...LegacyOK};
        case RESET_PURCHASE_IN_PROCESS:
            return {...LegacyOK};
        default:
            return state;
    }
};

const initialState = {
    id: "",
    receipt: undefined,
    product: {},
};

const currentStep = (state = initialState, action: any) => {
    const {type, payload} = action;
    switch (type) {
        case START_PURCHASE_FLOW:
            return {
                id: INIT,
                product: payload.product,
            };
        case DEPOSIT_STARTED:
            if (!state.id) return state;
            return {
                ...state,
                id: DEPOSIT,
                message: null,
                isError: false,
            };
        case CONFIRM_BET:
            return {
                ...state,
                id: CONFIRM,
                product: {
                    ...state.product,
                    coupon: payload.coupon,
                },
            };
        case VARENNE_BET_SUCCESS:
            // potentially user may have finished the purchase flow - in such case step id will be undefined and we should disregard the bet result
            if (!state.id) return state;
            return {
                ...state,
                id: VARENNE_PURCHASE,
                reducedBet: payload.reducedBet,
            };
        case SHOW_PURCHASE_RECEIPT:
            return {
                ...state,
                id: RECEIPT,
                receipt: payload,
            };
        case RESTORE_PURCHASE_FLOW:
            if (!payload || !payload.purchase) return state;
            return payload.purchase.currentStep;
        case DEPOSIT_FINISHED:
        case DEPOSIT_ABORTED:
            if (!state.id || state.id === KYC) return state;
            return {
                ...state,
                id: CONFIRM,
            };
        // The `FINISH_PURCHASE_FLOW` action is triggered in several situations, see
        // `purchaseFlowSaga` for details.
        //
        // **DO NOT** add more actions here – `purchaseFlowSaga` needs to be aware of all actions
        // that can "cancel" an ongoing purchase flow.
        case FINISH_PURCHASE_FLOW:
            return {};
        case CHANGE_HARRY_FLAVOUR: {
            return {
                ...state,
                product: {
                    ...state.product,
                    game: {
                        // @ts-expect-error
                        ...state.product.game,
                        harryFlavour: payload.harryFlavour,
                    },
                    coupon: {
                        // @ts-expect-error
                        ...state.product.coupon,
                        harryFlavour: payload.harryFlavour,
                    },
                },
            };
        }
        default:
            return state;
    }
};

const purchase = combineReducers({
    currentStep,
    status,
});

export default purchase;
