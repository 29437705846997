import {some, find} from "lodash/fp";
import {type Message} from "@atg-horse-shared/coupon-messages";
import type {GameTypes} from "@atg-horse-shared/game-types";
import {isReducedCouponTypes} from "@atg-horse-shared/utils/bet/betUtils";
import {isDivisionGameType} from "@atg-horse-shared/game-types";
// eslint-disable-next-line @nx/enforce-module-boundaries
import type {PurchaseState} from "@atg-horse-shared/purchase";
import {createSelector} from "reselect";
import * as Coupon from "./coupon";
import * as CouponValidation from "./couponValidation";
import {SYNCING, SYNCED, DELAYED, FAILED} from "./syncStatus";

export const getCoupon = (state: any, cid?: string) => (cid ? state.coupons[cid] : null);

export const hasCoupon = (state: any, cid?: string) =>
    (cid && Boolean(state.coupons[cid])) || null;

export const getCouponFromProduct = (state: PurchaseState & any) => {
    const cid = state.purchase.currentStep.product?.coupon?.cid;
    return cid ? state.coupons[cid] : null;
};

export const getCouponSettings = (state: any, cid: string) => state.couponSettings[cid];

export const getCouponValidation = (state: any, cid: string) =>
    state.couponValidations[cid];

export const getGlobalCouponSettings = (state: any) => state.globalCouponSettings;

export const isCouponLoading = (state: any, cid: string): boolean => {
    const loadingStatus = state.couponLoadingStatuses[cid];
    if (!loadingStatus) return false;
    return loadingStatus && loadingStatus.code === "loading";
};

export function getShowBaseHorses(state: any, cid: string) {
    const couponSettings = getCouponSettings(state, cid);
    if (!couponSettings) return false;

    const {showBaseHorses} = couponSettings;
    const {showBaseHorses: globalShowBaseHorses} = getGlobalCouponSettings(state);
    return showBaseHorses || globalShowBaseHorses;
}

export const getFirstCouponRace = (state: any, cid: string) => {
    const coupon = getCoupon(state, cid);
    if (!coupon) return null;

    return coupon.races[0];
};

export const getCouponRaceById = (
    state: any,
    cid: string | undefined,
    raceId: string,
) => {
    const coupon = getCoupon(state, cid);
    if (!coupon) return null;
    return Coupon.getRaceById(coupon, raceId);
};

export const getCouponBetMethod = (state: any, cid: string | undefined) => {
    const coupon = getCoupon(state, cid);
    if (!coupon) return null;
    return coupon.betMethod;
};

export const getCouponFlexValue = (state: any, cid: string | undefined) => {
    const coupon = getCoupon(state, cid);
    if (!coupon) return null;
    return coupon.flexValue;
};

export const getCouponId = (state: any, cid: string | undefined) => {
    const coupon = getCoupon(state, cid);
    if (!coupon) return null;
    return coupon.id;
};

export const getCouponType = (state: any, cid: string | undefined) => {
    const coupon = getCoupon(state, cid);
    if (!coupon) return null;
    return coupon.type;
};

export const getCouponGameType = (state: any, cid: string | undefined) => {
    const coupon = getCoupon(state, cid);
    if (!coupon) return null;
    return coupon.game?.type;
};

export const getCouponBetCost = (state: any, cid: string | undefined) => {
    const coupon = getCoupon(state, cid);
    if (!coupon) return null;
    return coupon.betCost;
};

export const getHarryBetLimit = (state: any, cid: string | undefined) => {
    const coupon = getCoupon(state, cid);
    if (!coupon) return null;
    return coupon.harryBetLimit;
};

export const isHorseSelected = (
    state: any,
    cid: string | undefined,
    raceId: string,
    startId: string,
    prefix?: string,
    raketBetType?: GameTypes.vinnare | GameTypes.plats | string,
) => {
    const couponRace = getCouponRaceById(state, cid, raceId);
    if (!couponRace) return false;
    return Boolean(Coupon.isSelected(couponRace, startId, prefix, raketBetType));
};

export const isFlexValueValid = (state: any, cid: string): boolean => {
    const coupon = getCoupon(state, cid);
    if (!coupon) return true;

    return Coupon.isFlexValueValid(coupon);
};

export const getSelectedCombinations = (
    state: any,
    cid: string,
): Array<Array<string>> | null => {
    const coupon = getCoupon(state, cid);
    if (!coupon) return null;

    switch (coupon.game.type) {
        case "tvilling":
            return Coupon.getTvillingCombinations(coupon);
        case "komb":
            return Coupon.getKombCombinations(coupon);
        case "dd":
        case "ld":
            return Coupon.getDubbelCombinations(coupon);
        default:
            return null;
    }
};

export const getMessagesForCouponRace = (
    state: any,
    cid: string,
    couponRaceId: string,
    prefix?: string,
): Array<Message> => {
    const couponValidation = getCouponValidation(state, cid);
    if (!couponValidation) return [];

    return CouponValidation.getMessagesForRaceId(couponValidation, couponRaceId, prefix);
};

const getCouponValidationSelector = (state: any, cid: string) =>
    getCouponValidation(state, cid);

export const getValidationMessages = createSelector(
    getCouponValidationSelector,
    (couponValidation) => {
        if (!couponValidation) return [];

        return CouponValidation.getMessagesWithoutSubmitWarningMessages(couponValidation);
    },
);

export const shouldShowSubmitErrors = (state: any, cid: string): boolean => {
    const couponSettings = getCouponSettings(state, cid);
    if (!couponSettings) return false;

    return couponSettings.showSubmitErrors || false;
};

export const shouldFlashSubmitErrors = (state: any, cid: string): boolean => {
    const couponSettings = getCouponSettings(state, cid);
    if (!couponSettings) return false;

    return couponSettings.flashSubmitErrors || false;
};

/**
 * Check if the coupon is readOnly
 * Some coupons are marked as readOnly. E.g. shared coupons.
 * These should not be synced for example.
 */
export const isReadOnly =
    (id?: string) =>
    (state: any): boolean | undefined => {
        if (!id) return undefined;
        const coupon = getCoupon(state, state.couponIdToCouponCidMap[id]);

        return coupon?.readOnly;
    };

export const getCouponLinkInfo = (
    state: any,
    cid: string | undefined,
    raceId: string,
): string => {
    const coupon = getCoupon(state, cid);
    const couponRace = getCouponRaceById(state, cid, raceId);

    if (!couponRace || !coupon) return "-";

    const gameType = coupon.game.type;
    if (gameType === "raket") {
        const {betType} = couponRace;
        if (!betType) return "-";

        return betType === "vinnare" ? "V" : "P";
    }

    return couponRace.bets.length.toString() || "-";
};

export const couponCidsForGame = (state: any, gameId: string) =>
    state.couponCidsForGame[gameId];

export const getNumberOfBets = (state: any, cid: string, raceNumber: number) =>
    state.coupons[cid].races[raceNumber].bets.length;

export const couponHasDivisionBets = (state: any, cid: string) => {
    const coupon = getCoupon(state, cid);
    if (!coupon || !isDivisionGameType(coupon.game.type)) return false;
    return some((race) => race.bets.length > 0, coupon.races);
};

/**
 * checks if a coupon with id is synced or not
 * @param id - id of the coupon (NOTE: not cid! since the coupon is not synced if it doesn't have id.)
 */
export const isSynced =
    (id?: string | null) =>
    (state: any): boolean => {
        if (!id) return false;

        const cid = state.couponIdToCouponCidMap[id];

        return state.couponSyncStatuses[cid] === SYNCED;
    };

/**
 * checks if a coupon is syncing
 * @param cid - cid of the coupon
 */
export const isSyncing =
    (cid?: string) =>
    (state: any): boolean => {
        if (!cid) return false;

        return state.couponSyncStatuses[cid] === SYNCING;
    };

/**
 * checks if a sync of the coupon is delayed
 * @param cid - cid of the coupon
 */
export const isDelayed =
    (cid?: string) =>
    (state: any): boolean => {
        if (!cid) return false;

        return state.couponSyncStatuses[cid] === DELAYED;
    };

/**
 * checks if a sync of the coupon is failed
 * @param cid - cid of the coupon
 */
export const isFailed =
    (cid?: string) =>
    (state: any): boolean => {
        if (!cid) return false;

        return state.couponSyncStatuses[cid] === FAILED;
    };

/**
 * Get cid from id-to-cid map
 * @param couponId - cid of the coupon
 */
export const getCidByCouponId =
    (couponId: string) =>
    (state: any): string | undefined =>
        state.couponIdToCouponCidMap[couponId];

/**
 * Get cid of a non-reduced coupon for the game
 * @param gameId - gameId
 * @param localOnly - specifies if it should be localOnly
 * @param isReduced - specifies it it should be reduced
 */

export const getCidForGame =
    (gameId: string, localOnly?: boolean, shouldBeReduced?: boolean) =>
    (state: any): string | undefined => {
        const couponCids = state.couponCidsForGame[gameId];
        return find((cid) => {
            const isReduced = isReducedCouponTypes(state.coupons[cid].type);
            return (
                localOnly === Boolean(state.coupons[cid].localOnly) &&
                (shouldBeReduced ? isReduced : !isReduced)
            );
        }, couponCids);
    };
