// eslint-disable-next-line @nx/enforce-module-boundaries
import type {HorseState} from "@atg-horse-shared/horse-state";
import {
    RECEIVE_HARRY_BOY_FEE_BRACKETS,
    FETCH_HARRY_BOY_FEE_BRACKETS,
    type HarryBoyFeeBracketsAction,
} from "./harryBoyFeeActions";

export type Bracket = {
    from: number;
    fee: number;
    to: number;
};

export type Brackets = {
    brackets: Array<Bracket> | null | undefined;
};

export type HarryBoyFeeBracket = Brackets & {
    loading?: boolean;
    error?: boolean;
};
export type GlobalHarryBoyFeeBracketsState = {
    horse: HorseState;
};
const INITIAL_STATE: HarryBoyFeeBracket = {
    error: false,
    loading: false,
    brackets: null,
};

function harryFeeBrackets(state = INITIAL_STATE, action: HarryBoyFeeBracketsAction) {
    switch (action.type) {
        case FETCH_HARRY_BOY_FEE_BRACKETS: {
            if (state.brackets) return state;
            return {...INITIAL_STATE, loading: true};
        }
        case RECEIVE_HARRY_BOY_FEE_BRACKETS: {
            const {payload, error} = action;
            if (error)
                return {
                    ...INITIAL_STATE,
                    error: true,
                };

            return {...INITIAL_STATE, brackets: payload.brackets};
        }
        default:
            return state;
    }
}

export default harryFeeBrackets;
