import {
    SHOP_SHARE_SERVICE_URL,
    USER_SERVICE_URL,
    SHOP_SHARE_SERVICE_URL_TOKENIZED,
} from "@atg-shared/service-url";
import {fetchAuthorized} from "@atg-shared/auth";
import type {BetAction as Bet, BetResponse} from "@atg-horse-shared/bet-types";
import {FETCH, call} from "@atg-shared/fetch-redux";
import {
    getStatusFromResponse,
    type Response as HttpResponse,
} from "@atg-shared/response-mapping/deprecated_loadingStatus";
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as betActionTypes from "@atg-horse-shared/horse-bet-types/";

// eslint-disable-next-line @nx/enforce-module-boundaries
import type {PlaceReducedBetResponse} from "@atg-horse-shared/purchase-varenne";

export const resetStatus = (): betActionTypes.ResetStatusAction => ({
    type: betActionTypes.RESET_STATUS,
});

/**
 *  @deprecated
 *
 *  Usage of this has been purged new functions now use the actions in '/tillsammans-packages/atg-shops-shopShareBet/domain/shopShareBetActions.js'
 */
export const fetchBetByShareId = (
    shareId: string,
    betContextId: string,
): betActionTypes.BetFetchAction => ({
    type: FETCH,
    payload: {
        requestAction: betActionTypes.REQUEST_BET,
        receiveAction: betActionTypes.RECEIVE_BET,
        callApi: call(
            fetchAuthorized,
            `${SHOP_SHARE_SERVICE_URL_TOKENIZED}/shares/${shareId}/receipt`,
            undefined,
            {
                memberFlowEnabled: false,
                fallbackUrl: `${SHOP_SHARE_SERVICE_URL}/shares/${shareId}/receipt`,
            },
        ),
        context: {
            betContextId,
        },
    },
});

export const speedPayout = (betId: string): betActionTypes.SpeedPayoutAction => ({
    type: FETCH,
    payload: {
        requestAction: betActionTypes.REQUEST_BET_SPEED_PAYOUT,
        receiveAction: betActionTypes.RECEIVE_BET_SPEED_PAYOUT,
        callApi: call(fetchAuthorized, `${USER_SERVICE_URL}/bets/${betId}/speedpayout`, {
            method: "POST",
        }),
        context: {
            betContextId: betId,
        },
    },
});

export const fetchBetSummaries = ({
    from,
    to,
    sortBy,
    sortDirection,
    gameDate,
    page = 0,
    limit = 25,
    cache = false,
    appendData = false,
}: betActionTypes.FetchBetSummariesOptions): betActionTypes.BetFetchSummariesAction => {
    const offset = page * limit;

    const filterQuery = gameDate
        ? `gameDate=${gameDate}`
        : `timestamp_from=${from}&timestamp_to=${to}`;

    return {
        type: FETCH,
        payload: {
            requestAction: betActionTypes.REQUEST_BET_SUMMARIES,
            receiveAction: betActionTypes.RECEIVE_BET_SUMMARIES,
            callApi: call(
                fetchAuthorized,
                `/services/v1/user/bets?${filterQuery}&sortColumn=${sortBy}&sortDirection=${sortDirection}&offset=${offset}&limit=${limit}&cache=${String(
                    cache,
                )}&forceBetHistory=true`, // forceBetHistory flag tells backend it is ok to fetch from the horse bet history
            ),
            context: {
                page,
                limit,
                appendData,
            },
        },
    };
};

export const playedBet = (
    bet: betActionTypes.BetWithMappedLegacyFlavor,
    response: BetResponse,
    isReducedBet: boolean,
    isNewBettingSystem = false,
): betActionTypes.PlayedBetSuccessAction => ({
    type: betActionTypes.PLAYED_BET,
    payload: {
        bet: response,
        // @ts-expect-error
        couponId: bet.couponId,
        id: response.id,
        isReducedBet,
        isNewBettingSystem,
    },
});

export const playedBetError = (
    bet: betActionTypes.BetWithMappedLegacyFlavor,
    status: betActionTypes.ErrorResponseStatus | string,
    isReducedBet = false,
): betActionTypes.PlayedBetErrorAction => ({
    type: betActionTypes.PLAYED_BET,
    error: true,
    payload: {
        id: bet.couponId,
        bet,
        status,
        isReducedBet,
    },
});

export const playedBets = (betIds: Array<string>) => ({
    type: betActionTypes.PLAYED_BETS,
    payload: {betIds},
});

export const placingBet = () => ({
    type: betActionTypes.PLACING_BET,
});

export const requestBetByCode = (
    betCode: string,
): betActionTypes.RequestBetByCodeAction => ({
    type: betActionTypes.REQUEST_BET_BY_CODE,
    payload: {betCode},
});

export const receiveBetByCode = (
    betCode: string,
    bet: {data: Bet},
): betActionTypes.ReceiveBetByCodeAction => ({
    type: betActionTypes.RECEIVE_BET_BY_CODE,
    payload: {
        betCode,
        bet,
    },
});

export const receiveBetByCodeError = (
    betCode: string,
    res: {
        response: HttpResponse;
    },
): betActionTypes.ReceiveBetByCodeAction => {
    const formattedResponse = res.response ? res.response : res;

    return {
        type: betActionTypes.RECEIVE_BET_BY_CODE,
        error: true,
        payload: {
            betCode,
            // @ts-expect-error
            status: getStatusFromResponse(formattedResponse, {
                "404": "Ogiltig rättningskod. Rättningskoden är aktiv i 10 dagar.",
            }),
        },
    };
};

export const getBetByCode = (betCode: string): betActionTypes.GetBetByCodeAction => ({
    type: betActionTypes.GET_BET_BY_CODE,
    payload: {betCode},
});

export const startedVarenneBet = (): betActionTypes.StartedVarenneBetAction => ({
    type: betActionTypes.STARTED_VARENNE_FLOW,
});

export const varenneBetSuccess = ({
    cid,
    bet,
    reducedBet,
}: {
    cid?: string;
    bet?: {
        tsn: string;
        placedAt: string;
        offeringId: string;
    };
    reducedBet?: PlaceReducedBetResponse;
}): betActionTypes.VarenneBetSuccessAction => ({
    type: betActionTypes.VARENNE_BET_SUCCESS,
    payload: {
        cid,
        bet,
        reducedBet,
    },
});

export const varenneBetError = (): betActionTypes.VarenneBetErrorAction => ({
    type: betActionTypes.VARENNE_BET_ERROR,
});
