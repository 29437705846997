import {call, takeEvery, take, select, put, takeLatest} from "redux-saga/effects";
import dayjs from "dayjs";
import type {Dispatch} from "redux";
import type {GameType} from "@atg-horse-shared/game-types";
import {
    subscribe,
    checkVersionedPushMessageState,
    REFETCH_NEEDED,
    PUSH_IN_SYNC,
} from "@atg-frame-shared/push";
import {
    START_LISTENING_TO_CALENDAR_PUSH,
    STOP_LISTENING_TO_CALENDAR_PUSH,
    RECEIVE_CALENDAR,
    FIND_CALENDAR_FROM_GAME_TYPE,
    receiveCalendarPush,
    fetchCalendar,
    type StartListeningToCalendarPushAction,
    type StopListeningToCalendarPushAction,
    type JSONPatchData,
    type FindCalendarFromGameTypeAction,
} from "./calendarActions";
import * as CalendarSelectors from "./calendarSelectors";

const PUSH_TOPIC_ROOT = "racinginfo/calendarday/";

export const getPushMessageCallback =
    (dispatch: any, getState: any, date: string, topic: string): any =>
    (message: JSONPatchData): void => {
        const localVersion: number = CalendarSelectors.getCalendarDayVersion(
            getState(),
            date,
        );

        const pushStatus: string = checkVersionedPushMessageState(
            topic,
            localVersion,
            message,
        );

        switch (pushStatus) {
            case REFETCH_NEEDED:
                dispatch(fetchCalendar(date, message.version));
                break;
            case PUSH_IN_SYNC:
                dispatch(receiveCalendarPush(date, message));
                break;
            default:
                break;
        }
    };

export const getUnsubscribeAction =
    (date: string): any =>
    (action: StopListeningToCalendarPushAction): boolean =>
        action.type === STOP_LISTENING_TO_CALENDAR_PUSH && action.payload.date === date;

export function* calendarPushListener(
    dispatch: any,
    getState: any,
    subscribeFn: any,
    action: StartListeningToCalendarPushAction,
): Generator<any, any, unknown> {
    const {payload} = action;
    const topicDate = payload.date;
    const topic = PUSH_TOPIC_ROOT + topicDate;
    const messageCallback = yield call(
        getPushMessageCallback,
        dispatch,
        getState,
        topicDate,
        topic,
    );

    const unsubscribe = yield call(subscribeFn, topic, messageCallback);
    const unsubscribeAction = yield call(getUnsubscribeAction, topicDate);

    // @ts-expect-error
    yield take(unsubscribeAction);
    // @ts-expect-error
    yield call(unsubscribe);
}

export function* fetchCalendarDayFromGameType(
    gameType: string,
    currentDate: dayjs.Dayjs = dayjs(),
): Generator<any, any, unknown> {
    const formattedDate = currentDate.format("YYYY-MM-DD");
    yield put(fetchCalendar(formattedDate, undefined, true));
    yield take(RECEIVE_CALENDAR);
    const calendarDay = yield select((state) =>
        CalendarSelectors.getCalendarDay(state, formattedDate),
    );
    // @ts-expect-error
    if (calendarDay && !calendarDay.games[gameType as GameType]) {
        yield call(fetchCalendarDayFromGameType, gameType, currentDate.add(1, "day"));
    }
}

export function* findCalendarDayFromGameType(
    action: FindCalendarFromGameTypeAction,
): Generator<any, any, unknown> {
    yield call(fetchCalendarDayFromGameType, action.payload.gameType);
}

export default function* calendarSaga(
    dispatch: Dispatch,
    getState: () => any,
): Generator<any, any, unknown> {
    yield takeEvery(
        START_LISTENING_TO_CALENDAR_PUSH,
        calendarPushListener,
        dispatch,
        getState,
        subscribe,
    );
    yield takeLatest(FIND_CALENDAR_FROM_GAME_TYPE, findCalendarDayFromGameType);
}
