import {every, get} from "lodash";
import {deprecated_formatCurrency} from "@atg-shared/currency";
import {formatNumber} from "@atg/utils/strings";
import {t} from "@lingui/macro";
import type {RoleType} from "@atg-shop-shared/types/role";
import {Role} from "@atg-shop-shared/types/role";
import type {ShopShare} from "@atg-shop-shared/types/shopShare";
import type {
    HorseGame,
    HorseGameRace,
    HorseGameStart,
} from "@atg-shop-shared/types/horseGameTypes";
import type {ShareCoupon, ShareCouponRace} from "@atg-shop-shared/types/shareCoupon";

export const getNumberOfSystems = (shopShare: ShopShare): number =>
    get(shopShare, "sales.multiplicator", 1);

export const getFormattedNumberOfSystems = (shopShare: ShopShare): string =>
    formatNumber(getNumberOfSystems(shopShare), {});

export const getBettableAmount = ({sales}: ShopShare): number =>
    sales ? sales.totalSalesAmount - sales.commissionAmount : 0;

// TODO: This should be provided by the backend, but because of SHOPS-1141 we need to do this here.
export const getTotalCost = (betCost: number, shopShare: ShopShare): number => {
    const {sales, sharesPerSystem} = shopShare;
    if (!sales || sales.soldShares <= sharesPerSystem) return betCost;
    return Math.round(betCost * (sales.soldShares / sharesPerSystem));
};

export const getFormattedTotalCost = (betCost: number, shopShare: ShopShare): string =>
    deprecated_formatCurrency(getTotalCost(betCost, shopShare));

export const getFormattedBettableAmount = (shopShare: ShopShare): string =>
    deprecated_formatCurrency(getBettableAmount(shopShare));

export const getFormattedTotalStakeEquation = (
    betCost: number,
    shopShare: ShopShare,
): string =>
    `${deprecated_formatCurrency(betCost)} x ${getFormattedNumberOfSystems(
        shopShare,
    )} = `;

export const hasAtLeastOneShareSold = ({sales}: ShopShare): boolean =>
    sales !== undefined && sales.soldShares > 0;

export const hasAtLeastOneChosenHorseForEachRaceInCoupon = ({races}: ShareCoupon) =>
    every(races, (race) => race.bets.length > 0);

export const hasEnoughBalanceForBet = (betCost: number, shopShare: ShopShare) =>
    getBettableAmount(shopShare) >= getTotalCost(betCost, shopShare);

export const getMaxBetsForRace = ({starts}: HorseGameRace) => {
    const numberOfScratched = starts
        .filter((start) => start.scratched)
        .map((start) => start.id).length;
    const numberOfStarts = starts.length ?? 0;
    return numberOfStarts - numberOfScratched;
};

export const isExceedingMaxBets = (
    shareRace: ShareCouponRace,
    gameInfoRace: HorseGameRace,
) => {
    const noOfBets = shareRace.bets.length;
    if (noOfBets === 0 || !gameInfoRace) return false;
    const maxAllowedBets = getMaxBetsForRace(gameInfoRace);
    return maxAllowedBets < noOfBets;
};

export const isValidRow = (shareRace: ShareCouponRace, gameInfoRace: HorseGameRace) => {
    isExceedingMaxBets(shareRace, gameInfoRace);
};

export const isValidCoupon = (
    coupon: ShareCoupon,
    gameInfo: HorseGame,
    messageCallback?: (message: string) => void,
): boolean => {
    let valid = true;
    for (let step = 0; step < coupon.races.length; step += 1) {
        if (isExceedingMaxBets(coupon.races[step], gameInfo.races[step])) {
            valid = false;
            if (messageCallback)
                messageCallback(
                    t`Du har markerat fler hästar än vad som startar i avdelning ${
                        step + 1
                    }. Justera spelet med Redigera kupong.`,
                );
            break;
        }
    }
    return valid;
};

export const isShopShareBetPossible = (
    coupon: ShareCoupon,
    shopShare: ShopShare,
): boolean =>
    hasAtLeastOneShareSold(shopShare) &&
    hasAtLeastOneChosenHorseForEachRaceInCoupon(coupon) &&
    hasEnoughBalanceForBet(coupon.betCost, shopShare);

export const isInitiatorOrAdminOrOwner = (role: RoleType) =>
    [Role.OWNER, Role.ADMINISTRATOR, Role.INITIATOR].includes(role);

export const isShopOwner = (role: RoleType): boolean => role === Role.OWNER;

export const isAdmin = (role: RoleType): boolean => role === Role.ADMINISTRATOR;

export const isAdminOrOwner = (role: RoleType) =>
    [Role.OWNER, Role.ADMINISTRATOR].includes(role);

export const getBoxedBetsWithHorseNames = (
    boxedBets: Array<Array<number | null>>,
    horseGameStarts?: HorseGameStart[],
) =>
    boxedBets.map((bets) => {
        if (bets.length === 1 && horseGameStarts) {
            const horseNumber = bets[0];
            const matchingHorse = horseGameStarts.find(
                (horse) => horse.number === horseNumber,
            );
            return [
                {
                    number: horseNumber,
                    name: matchingHorse ? matchingHorse.horse.name : "",
                },
            ];
        }
        return bets;
    });
