import type {SagaIterator} from "redux-saga";
import {call, take, takeEvery, type ActionPattern} from "redux-saga/effects";
import * as Analytics from "@atg-shared/analytics";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {PLAYED_BET} from "@atg-horse-shared/horse-bet-types";
import {
    HARRY_SUBSCRIPTION_PURCHASED,
    FINISH_PURCHASE_FLOW,
    SHOW_LOW_BALANCE_MESSAGE,
} from "./purchaseActions";
import * as PurchaseBalanceAnalytics from "./purchaseBalanceAnalytics";

export function* takeSuccessful(actions: ActionPattern): SagaIterator<void> {
    let action;
    do {
        action = yield take(actions);
    } while (action.error);
    return action;
}

export function* lowBalanceFlow({payload}: {[key: string]: any}): SagaIterator<void> {
    const {betCost: desiredCost} = payload;
    const checkoutLowBalanceEvent = yield call(
        PurchaseBalanceAnalytics.checkoutLowBalance,
    );
    yield call(Analytics.deprecated_logEvent, checkoutLowBalanceEvent);

    const action = yield call(takeSuccessful, [
        PLAYED_BET,
        HARRY_SUBSCRIPTION_PURCHASED,
        FINISH_PURCHASE_FLOW,
    ]);

    if (action.type === FINISH_PURCHASE_FLOW) {
        yield call(cancelPurchaseFlow);
        return;
    }

    yield call(purchaseCheaperOptionFlow, action, desiredCost);
}

export function* updateBalanceFlow(): SagaIterator<void> {
    yield take([FINISH_PURCHASE_FLOW]);

    // deposit cancel
    const depositCancelEvent = yield call(PurchaseBalanceAnalytics.depositCancel);
    yield call(Analytics.deprecated_logEvent, depositCancelEvent);
}

export function* cancelPurchaseFlow(): SagaIterator<void> {
    const purchaseLowBalanceCancelEvent = yield call(
        PurchaseBalanceAnalytics.purchaseLowBalanceCancel,
    );
    yield call(Analytics.deprecated_logEvent, purchaseLowBalanceCancelEvent);
}

export function* purchaseCheaperOptionFlow(
    action: {
        [key: string]: any;
    },
    desiredCost: number,
): SagaIterator<void> {
    const {type, payload} = action;
    const playedBetCost = type === PLAYED_BET && payload.bet.cost;
    const harrySubCost =
        type === HARRY_SUBSCRIPTION_PURCHASED && payload.subscriptionResponse.maxAmount;

    const actualCost = playedBetCost || harrySubCost;

    if (actualCost < desiredCost) {
        const purchaseLowBalanceCheaperEvent = yield call(
            PurchaseBalanceAnalytics.purchaseLowBalanceCheaperOption,
            desiredCost,
            actualCost,
        );
        yield call(Analytics.deprecated_logEvent, purchaseLowBalanceCheaperEvent);
    }
}

export default function* purchaseBalanceSaga(): SagaIterator<void> {
    yield takeEvery(SHOW_LOW_BALANCE_MESSAGE, lowBalanceFlow);
}
