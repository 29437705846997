import {flow, compact, tail} from "lodash/fp";
import {createSelector} from "reselect";
import queryString from "query-string";
import type {GlobalRouterState as State} from "./routerReducer";

type Location = {
    pathname: string;
    search: string;
};

export const getLocation = (state: State) =>
    state.router ? state.router.location : ({} as Location);

export const getPath = (state: State) => {
    const location = getLocation(state);
    return location.pathname ?? null;
};

export const getQuery = createSelector(getLocation, (location) =>
    queryString.parse(location.search || ""),
);
export const getSearch = (state: State) => getLocation(state).search || "";
export const cleanQuery = (state: State, parameters: string | Array<string>) => {
    const query = getQuery(state);

    if (Array.isArray(parameters)) {
        parameters.forEach((parameter) => delete query[parameter]);
    } else if (parameters) {
        delete query[parameters];
    }

    return queryString.stringify(query);
};

export const getQueryParam = (state: State, paramName: string) => {
    const query = getQuery(state);

    if (!query) return null;
    return query[paramName] || null;
};

const lastPathPart = flow(compact, tail);

export const getParsedGamePath = (state: State) => {
    const path = getPath(state);
    if (!path) return null;

    return lastPathPart(path.split("/"));
};
