// eslint-disable-next-line @nx/enforce-module-boundaries
import type {PlaceReducedBetResponse} from "@atg-horse-shared/purchase-varenne";
import type {
    DetailedBet,
    BetAction as Bet,
    BetResponse,
} from "@atg-horse-shared/bet-types";
import {type FetchAction} from "@atg-shared/fetch-types";
import type {State} from "..";
import type {BetSummary} from "./bet-summary-types";

export const BET_REFUND_SUCCESS_STATUS = "bet/betRefundSuccessStatus" as const;
export const CLEAR_LEGACY_BET_SUMMARIES = "bet/clearLegacyBetSummaries" as const;
export const GET_BET_BY_CODE = "bet/getBetByCode" as const;
export const PLACE_BET = "bet/placeBet" as const;
export const PLACING_BET = "bet/placingBet" as const;
export const PLAYED_BET = "bet/playedBet" as const;
export const PLAYED_BETS = "bet/playedBets" as const;
export const RECEIVE_BET = "bet/receiveBet" as const;
export const RECEIVE_BET_BY_CODE = "bet/receiveBetByCode" as const;
export const RECEIVE_BET_REFUND = "bet/receiveBetRefund" as const;
export const RECEIVE_BET_SPEED_PAYOUT = "bet/receiveBetSpeedPayout" as const;
export const RECEIVE_BET_SUMMARIES = "bet/receiveBetSummaries" as const;
export const RECEIVE_CORRECTED_BET = "bet/receiveCorrectedBet" as const;
export const RECEIVE_LEGACY_BET_SUMMARIES = "bet/receiveLegacyBetSummaries" as const;
export const RECEIVE_UPDATED_BET = "bet/receiveUpdatedBet" as const;
export const REQUEST_BET = "bet/requestBet" as const;
export const REQUEST_BET_BY_CODE = "bet/requestBetByCode" as const;
export const REQUEST_BET_REFUND = "bet/requestBetRefund" as const;
export const REQUEST_BET_SPEED_PAYOUT = "bet/requestBetSpeedPayout" as const;
export const REQUEST_BET_SUMMARIES = "bet/requestBetSummaries" as const;
export const REQUEST_CORRECTED_BET = "bet/requestCorrectedBet" as const;
export const REQUEST_LEGACY_BET_SUMMARIES = "bet/requestLegacyBetSummaries" as const;
export const REQUEST_UPDATED_BET = "bet/requestUpdatedBet" as const;
export const RESET_BET_ACTION_STATUS = "bet/requestBetCorrectionStatus" as const;
export const RESET_STATUS = "bet/resetStatus" as const;
export const SET_LIVE_BETS_FILTER = "bet/setLiveBetsFilter" as const;
export const START_REQUEST_LIVE_BETS = "bet/startRequestLiveBets" as const;
export const STARTED_VARENNE_FLOW = "bet/startedVarenneFlow" as const;
export const STOP_REQUEST_LIVE_BETS = "bet/stopRequestLiveBets" as const;
export const VARENNE_BET_ERROR = "bet/varenneBetError" as const;
export const VARENNE_BET_SUCCESS = "bet/varenneBetSuccess" as const;

export type Action =
    | BetFetchAction
    | BetFetchSummariesAction
    | SpeedPayoutAction
    | ResetStatusAction
    | PlayedBetAction
    | GetBetByCodeAction
    | ReceiveBetByCodeAction
    | RequestBetByCodeAction;

export type BetContext = {
    betContextId: string;
};

export type BetSummariesContext = {
    page: number;
    limit: number;
    appendData?: boolean;
};

type SpeedActionPayload = {
    amount: number;
    fee: number;
    timestamp: string;
};

export type BetFetchAction = FetchAction<
    typeof REQUEST_BET,
    typeof RECEIVE_BET,
    DetailedBet,
    State,
    BetContext
>;

export type BetFetchSummariesAction = FetchAction<
    typeof REQUEST_BET_SUMMARIES,
    typeof RECEIVE_BET_SUMMARIES,
    {data: Array<BetSummary>; totalCount: number},
    State,
    BetSummariesContext
>;

export type ResetStatusAction = {
    type: typeof RESET_STATUS;
};

export type SpeedPayoutAction = FetchAction<
    typeof REQUEST_BET_SPEED_PAYOUT,
    typeof RECEIVE_BET_SPEED_PAYOUT,
    SpeedActionPayload,
    BetContext
>;

export type FetchBetSummariesOptions = {
    from?: string;
    to?: string;
    sortBy: string;
    sortDirection: string;
    gameDate?: string;
    page?: number;
    limit?: number;
    cache?: boolean;
    appendData?: boolean;
};

export type BetWithMappedLegacyFlavor = Omit<Bet, "harryFlavour"> & {
    harryFlavour?: "chans" | "favorit";
};

export type PlayedBetSuccessAction = {
    type: typeof PLAYED_BET;
    error?: boolean;
    payload: {
        bet: BetResponse;
        couponId: string | null;
        id: string;
        isReducedBet: boolean;
        isNewBettingSystem: boolean;
    };
};

/**
 * Old error message format. Check function getBetResponseStatus in betAPI.js
 * TODO: Remove when new error format is fully implemented
 */
export type ErrorResponseStatus = {
    code?: string;
    error: {
        httpCode?: string | number;
        message: string;
        reason?: string;
    };
    isLoading?: boolean;
};

export type PlayedBetErrorAction = {
    type: typeof PLAYED_BET;
    error: boolean;
    payload: {
        id: string | null | undefined;
        bet: BetWithMappedLegacyFlavor;
        status: ErrorResponseStatus | string;
        isReducedBet: boolean;
    };
};

export type PlayedBetAction = PlayedBetSuccessAction | PlayedBetErrorAction;

export type GetBetByCodeAction = {
    type: typeof GET_BET_BY_CODE;
    payload: {
        betCode: string;
    };
};

export type ReceiveBetByCodeAction = {
    type: typeof RECEIVE_BET_BY_CODE;
    error?: boolean;
    payload: {
        betCode: string;
        bet?: {
            data: Bet;
        };
        status?: unknown;
    };
};

export type RequestBetByCodeAction = {
    type: typeof REQUEST_BET_BY_CODE;
    payload: {
        betCode: string;
    };
};

export type ReceiveBetSpeedPayoutAction = {
    type: typeof RECEIVE_BET_SPEED_PAYOUT;
    context: {
        betContextId: string;
    };
    payload: {
        amount: number;
        fee: number;
        timestamp: string;
    };
};

export type StartedVarenneBetAction = {
    type: typeof STARTED_VARENNE_FLOW;
};

export type VarenneBetSuccessAction = {
    type: typeof VARENNE_BET_SUCCESS;
    payload: {
        cid?: string;
        bet?: {
            tsn: string;
            placedAt: string;
            offeringId: string;
        };
        reducedBet?: PlaceReducedBetResponse;
    };
};

export type VarenneBetErrorAction = {
    type: typeof VARENNE_BET_ERROR;
};
