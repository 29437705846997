import {find, isEmpty, head} from "lodash";
import type {Store} from "redux";
import {call, put, take, takeEvery, select} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import queryString from "query-string";
import type {Location} from "react-router-dom";
import {RouterActions} from "@atg-shared/router";
import log from "@atg-shared/log";
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as ProductActions from "@atg-horse/product-pages/domain/productActions";
import {type GameType, ALL_GAMES} from "@atg-horse-shared/game-types";
import {fetchGame} from "@atg-horse-shared/racing-info-api";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
    HarryBoyFeeSelectors,
    Top7SystemUtils,
    HarryBoyFeeActions,
    HarryBetLimitConverters,
    harryBoyFeeBracketsFetchFlow,
    CouponApi,
    CouponDefs,
} from "@atg-horse-shared/coupon";
import {logoutUser} from "@atg-global-shared/user/userActions";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
    fetchHarryBags,
    fetchHarrySubscriptions,
} from "@atg-horse-shared/horse-harry/domain/harryAPI";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {SharedBetProducts, SharedBetApi, SharedBetActions} from "@atg-horse/shared-bet";
import browserHistory from "atg-history";
import {frameAction} from "atg-store-addons";
import * as PurchaseActions from "./purchaseActions";
import * as Products from "./products";

type ExternalPurchaseQuery = {
    modal: string;
    game: GameType;
    limit: string;
    boost: string;
    subscription: string;
    external: string;
    bag: string;
    couponId: string;
    sharedBet: string;
    showDeliveryOptions: string;
};

/**
 * This function loads the `couponChunk` whenever purchase action is called.
 * This is needed since external links can redirect the user to the homepage with
 * a specific query like `?game=V75&modal=harry&boost=true`. And the ATG homepage
 * does not contain the coupon yet and it needs to be loaded before the purchase action is
 * called.
 * See jira:https://jira-atg.riada.cloud/browse/LIVE-152
 */
export const lazyLoadCouponChunks = async () =>
    // eslint-disable-next-line @nx/enforce-module-boundaries
    import(
        /* webpackChunkName: "couponChunk" */
        "@atg-horse-shared/coupon"
    ).then((module) => module.default);

const isValidGameType = (gameType: GameType) =>
    // @ts-expect-error Argument of type 'string' is not assignable to parameter of type 'GameTypes'
    ALL_GAMES.includes(gameType.toUpperCase()) ||
    // @ts-expect-error
    ALL_GAMES.includes(gameType.toLowerCase());

const isHarryPurchase = (query: ExternalPurchaseQuery) =>
    query.modal === "harry" && isValidGameType(query.game);

export const isHarrySubscription = (query: ExternalPurchaseQuery) =>
    query.modal === "harrysubscription" && isValidGameType(query.game);

const isTop7Purchase = (query: ExternalPurchaseQuery) =>
    !query.modal && query.game === "top7";

const isHarryBagPurchase = (query: ExternalPurchaseQuery) =>
    query.modal === "harrybag" && !isEmpty(query.bag);

const isSharedBetPurchase = (query: ExternalPurchaseQuery) =>
    query.sharedBet === "true" && !isEmpty(query.couponId);

export const isExternalCampaign = (query: ExternalPurchaseQuery) =>
    query.external === "true";

export const getAmountFromLimit = (limit: string) =>
    limit ? parseInt(limit, 10) * 100 : 0;

// Always clear modal-triggering params from address bar to avoid reload issues with modal
export const clearQueryParams = (location: Location) => {
    const onlyUtmParams = location.search
        .substring(1)
        .split("&")
        .filter((q: string) => q.startsWith("utm_"))
        .join("&");

    browserHistory.replace(`${location.pathname}?${onlyUtmParams}`);
};

function* harryPurchase(location: Location, store: Store<any, any>): SagaIterator<void> {
    const query = queryString.parse(location.search);
    const {game, limit, boost: _boost, subscription} = query;
    yield put(ProductActions.fetchGameSchedule("harry"));

    const {payload, error} = yield take(ProductActions.RECEIVE_GAME_SCHEDULE);
    if (error) return;

    const harryGame = find(payload.games, {type: game});
    if (!harryGame) return;

    // @ts-expect-error
    const amount = getAmountFromLimit(limit);
    const boost = _boost === "true";
    const shouldUpSellHarrySubscription = subscription === "true";

    const harryProduct = yield call(Products.harryProduct, harryGame, amount, {boost});

    // Load the couponChunk before purchase action
    // see jira:https://jira-atg.riada.cloud/browse/LIVE-152
    const couponChunk = yield call(lazyLoadCouponChunks);
    couponChunk(store);

    yield put(PurchaseActions.purchase(harryProduct, {shouldUpSellHarrySubscription}));
    yield call(clearQueryParams, location);
}

function* top7Purchase(location: Location, store: Store<any, any>): SagaIterator<void> {
    const query = queryString.parse(location.search);
    const {limit, subscription} = query;
    yield put(ProductActions.fetchGameSchedule("top7")); // Do API request inline instead of via thunk?

    const {payload, error} = yield take(ProductActions.RECEIVE_GAME_SCHEDULE);
    if (error) return;

    const top7Game = head(payload.upcoming);
    if (!top7Game) return;

    // @ts-expect-error
    const amount = getAmountFromLimit(limit);
    const shouldUpSellHarrySubscription = subscription === "true";

    const harryBoyFeeBrackets = yield select(HarryBoyFeeSelectors.getHarryBoyFeeBrackets);

    // Check if the request is being exectued if not a new call will be made
    // for fetching the feebracket
    let fetchedFeeBracket = null;
    if (!harryBoyFeeBrackets.loading && !harryBoyFeeBrackets.brackets) {
        fetchedFeeBracket = yield call(harryBoyFeeBracketsFetchFlow, {
            type: "coupon/fetchHarryBoyFeeBrackets",
            payload: {gameType: "top7"},
        });
    }
    // If the request is being executed, await tshe data to be recieved
    if (harryBoyFeeBrackets.loading && !fetchedFeeBracket) {
        yield take(HarryBoyFeeActions.RECEIVE_HARRY_BOY_FEE_BRACKETS);
        fetchedFeeBracket = yield select(HarryBoyFeeSelectors.getHarryBoyFeeBrackets);
    }
    const harryBetLimitWithFee = HarryBetLimitConverters.addFeeToBetLimit(
        amount,
        fetchedFeeBracket || harryBoyFeeBrackets,
        "top7",
    );
    // Default since it cannot have a banker when it comes from external api
    const systemId = Top7SystemUtils.findSystemBasedOnProperty(
        CouponDefs.top7.costToSystemIds[amount],
        "default",
    );

    const top7Product = yield call(
        // @ts-expect-error
        Products.top7Product,
        top7Game,
        amount, // If the feeBrackets cant be fetched and the `harryBetLimitWithFee` is false
        // user will get a error message in the modal
        harryBetLimitWithFee || undefined, // Need a systemId when a autofill is placed, it will always be default
        systemId,
    );

    // Load the couponChunk before purchase action
    // see jira:https://jira-atg.riada.cloud/browse/LIVE-152
    const couponChunk = yield call(lazyLoadCouponChunks);
    couponChunk(store);

    yield put(PurchaseActions.purchase(top7Product, {shouldUpSellHarrySubscription}));
    yield call(clearQueryParams, location);
}

function* harryBagPurchase(
    location: Location,
    store: Store<any, any>,
): SagaIterator<void> {
    const query = queryString.parse(location.search);
    // @ts-expect-error
    const bagId = parseInt(query.bag, 10);
    let harryGroups;
    try {
        const response = yield call(fetchHarryBags);
        harryGroups = response.data;
    } catch (error: unknown) {
        yield call(log.warn, "Failed to fetch Harry Bags");
        return;
    }

    const matchingGroup = find(harryGroups.groups, (group) => group.id === bagId);
    if (isEmpty(matchingGroup)) return;

    // Load the couponChunk before purchase action
    // see jira:https://jira-atg.riada.cloud/browse/LIVE-152
    const couponChunk = yield call(lazyLoadCouponChunks);
    couponChunk(store);

    yield put(PurchaseActions.purchase(Products.harryBagProduct(matchingGroup)));
    yield call(clearQueryParams, location);
}

/**
 * Test urls (run from startpage):
 * ?game=V75&limit=200&modal=harry&boost=true
 * ?game=top7&limit=24
 */
type HarrySubscriptionPurchaseProps = {
    query: ExternalPurchaseQuery;
};
export function* harrySubscriptionPurchase(
    {query}: HarrySubscriptionPurchaseProps,
    store: Store<any, any>,
): SagaIterator<void> {
    let product;

    try {
        const response = yield call(fetchHarrySubscriptions);
        // @ts-expect-error
        product = yield call(find, response.data.subscriptions, {
            betType: query.game.toUpperCase(),
        });

        if (!product) {
            // @ts-expect-error
            product = yield call(find, response.data.subscriptions, {
                betType: query.game.toLowerCase(),
            });
        }
    } catch (e: unknown) {
        const error = e as any;
        yield call(log.warn, `Harry subscriptions fetch error: ${error.message}`);
    }

    if (product) {
        const {limit, showDeliveryOptions} = query;

        if (showDeliveryOptions) {
            product = {...product, showDeliveryOptions: showDeliveryOptions === "true"};
        }
        const subscriptionLimit = limit ? getAmountFromLimit(limit) : undefined;

        // Load the couponChunk before purchase action
        // see jira:https://jira-atg.riada.cloud/browse/LIVE-152
        const couponChunk = yield call(lazyLoadCouponChunks);
        couponChunk(store);

        yield put(
            PurchaseActions.purchase(
                Products.harrySubscriptionProduct(product, subscriptionLimit),
            ),
        );
    }
}

export function* sharedBetPurchase(
    couponId: string,
    store: Store<any, any>,
): SagaIterator<void> {
    try {
        const {data: conditions} = yield call(SharedBetApi.fetchConditions, couponId);

        if (conditions.totalNrSoldShares === conditions.totalNrShares) {
            yield put(SharedBetActions.openNotFoundModal());
            return;
        }

        const {data: coupon} = yield call(CouponApi.getCoupon, couponId);
        const {data: game} = yield call(fetchGame, coupon.game.id);

        const product = SharedBetProducts.sharedBetShareProduct(game, coupon, conditions);

        // Load the couponChunk before purchase action
        // see jira:https://jira-atg.riada.cloud/browse/LIVE-152
        const couponChunk = yield call(lazyLoadCouponChunks);
        couponChunk(store);

        yield put(PurchaseActions.purchase(product));
    } catch (error: unknown) {
        yield put(SharedBetActions.openNotFoundModal());
    }
}

type QueryTriggerProps = {
    payload: {
        location: Location;
    };
};

export function* queryTrigger(
    store: Store<any, any>,
    {payload}: QueryTriggerProps,
): SagaIterator<void> {
    const query: any = queryString.parse(payload.location.search);

    if (isEmpty(query)) return;

    if (isExternalCampaign(query)) {
        // User comes from an external ad, LI demands us to log out the user if logged in.
        yield put(frameAction(logoutUser({soft: true})));
    }

    if (isTop7Purchase(query)) {
        // Check pathname from external links
        // see jira:https://jira-atg.riada.cloud/browse/LIVE-152
        log.warn("externalPurchaseSaga: Top7 queryTrigger check pathname", {
            path: payload.location.pathname,
            type: "top7",
        });
        // clear any saved purchase state
        yield put(PurchaseActions.finishPurchase());
        yield call(top7Purchase, payload.location, store);
    } else if (isHarryPurchase(query)) {
        // Check pathname from external links
        // see jira:https://jira-atg.riada.cloud/browse/LIVE-152
        log.warn("externalPurchaseSaga: HarryPurchase queryTrigger check pathname", {
            path: payload.location.pathname,
            type: "harryPurchase",
        });
        yield put(PurchaseActions.finishPurchase());
        yield call(harryPurchase, payload.location, store);
    } else if (isHarrySubscription(query)) {
        // Check pathname from external links
        // see jira:https://jira-atg.riada.cloud/browse/LIVE-152
        log.warn("externalPurchaseSaga: Harry subscription queryTrigger check pathname", {
            path: payload.location.pathname,
            type: "harrySubscription",
        });
        yield put(PurchaseActions.finishPurchase());
        yield call(harrySubscriptionPurchase, {query}, store);
    } else if (isHarryBagPurchase(query)) {
        // Check pathname from external links
        // see jira:https://jira-atg.riada.cloud/browse/LIVE-152
        log.warn("externalPurchaseSaga: HarryBag queryTrigger check pathname", {
            path: payload.location.pathname,
            type: "harryBag",
        });
        yield put(PurchaseActions.finishPurchase());
        yield call(harryBagPurchase, payload.location, store);
    } else if (isSharedBetPurchase(query)) {
        // Check pathname from external links
        // see jira:https://jira-atg.riada.cloud/browse/LIVE-152
        log.warn("externalPurchaseSaga: SharedBet queryTrigger check pathname", {
            path: payload.location.pathname,
            type: "sharedBet",
        });
        yield put(PurchaseActions.finishPurchase());
        yield call(sharedBetPurchase, query.couponId, store);
    }
}

export default function* locationChangeSaga(store: Store<any, any>): SagaIterator<void> {
    // call queryTrigger saga once initially since RouterActions.ROUTER_ON_LOCATION_CHANGED action might have been dispatched before the saga was registered
    const location = yield select((state) => state.router.location);
    yield call(queryTrigger, store, {payload: {location}});
    // @ts-expect-error
    yield takeEvery(RouterActions.ROUTER_ON_LOCATION_CHANGED, queryTrigger, store);
}
