import {map} from "lodash";
import {map as _map} from "lodash/fp";
import {
    all,
    call,
    race,
    delay,
    fork,
    put,
    select,
    take,
    takeEvery,
} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import {Reason} from "@atg-shared/response-mapping/get409ErrorMessage";
import * as Analytics from "@atg-shared/analytics";
import {AuthActions} from "@atg-shared/auth";
import log, {serializeError} from "@atg-shared/log";
import {mapHarryFlavorToLegacyBetValue} from "@atg-horse-shared/utils/harry";
import type {AtgRequestError} from "@atg-shared/fetch-types";
import * as ShopUtils from "@atg-shop-shared/utils/shopUtils";
import type {CouponType, Top7Coupon} from "@atg-horse-shared/coupon-types";
import {AtgAlertTypes} from "@atg-global-shared/alerts-types";
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as CalendarSelectors from "@atg-horse-shared/calendar/domain/calendarSelectors";
import * as UserActions from "@atg-global-shared/user/userActions";
import * as UserSelectors from "@atg-global-shared/user/userSelectors";
import {
    RECEIVE_BALANCE,
    RECEIVE_BALANCE_ERROR,
} from "@atg-global-shared/user/userActionTypes";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {CouponUtils, CouponActions, CouponLocalApi} from "@atg-horse-shared/coupon";
import {MemberActions} from "@atg-global-shared/member-data-access";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {getRankingGroups} from "@atg-shop-shared/shop-coupon-utils/src/shareCouponUtils";
import {constants} from "@atg-responsible-gambling-shared/limits-types";
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as ShopPurchaseActions from "@atg-shop-shared/purchase-redux/src/shopPurchaseActions";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
    SHOP_SHARE_COUPON_PRODUCT,
    type ShopShareCouponProduct,
} from "@atg-shop-shared/purchase-redux/src/shopShareCouponProducts";
// atg-horse-bet
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as ShopShareSelectors from "@atg-shop-shared/shops-domain-redux/src/shopShareSelectors";
// atg-horse-bet
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as ShopShareActions from "@atg-shop-shared/shops-domain-redux/src/shopShareActions";
import {
    LimitsActions,
    LimitsSelectors,
} from "@atg-responsible-gambling-shared/limits-domain";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {getShopShareResponseStatus} from "@atg-shop-shared/purchase-redux/src/errorResponse";
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as BetAPI from "@atg-horse-shared/bet-api";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {BetActions, ReduxBetSelectors as BetSelectors} from "@atg-horse/horse-bet";

// eslint-disable-next-line @nx/enforce-module-boundaries
import * as betActionTypes from "@atg-horse-shared/horse-bet-types/";

import {
    kycFlowBeforeBet,
    showKycModalBeforeBet,
} from "@atg-aml-shared/kyc-domain/src/saga/kycQuestionnaireSaga";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
    SharedBetProducts,
    SharedBetActions,
    SharedBetApi,
    isSharedBetCouponProduct,
    createSharedBet,
    getCreateSharedBetResponseStatus,
    getPurchaseShareResponseStatus,
    purchaseSharesSharedBet,
    sharedBetConditions,
} from "@atg-horse/shared-bet";
import {SharedBetApiUtils} from "@atg-tillsammans-shared/shared-bet-utils";
import features, {useMaxStakePerSystem} from "@atg-shared/client-features";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {getCost} from "@atg-horse/horse-bet/src/domain/bet";
import * as ToastActions from "atg-ui-toast/domain/toastActions";
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as GameSelectors from "atg-horse-game/domain/gameSelectors";
import {CLOSE_ALL_MODALS} from "atg-modals/modalActionTypes";
import * as PurchaseSelectors from "./purchaseSelectors";
import * as PurchaseActions from "./purchaseActions";
import * as PurchaseBalanceAnalytics from "./purchaseBalanceAnalytics";
import * as Products from "./products";

type PurchaseAction = {
    type:
        | typeof PurchaseActions.START_PURCHASE_FLOW
        | typeof PurchaseActions.RESTORE_PURCHASE_FLOW;
    payload: {
        product: Products.Product;
    };
};

export function* getReceiptData(
    product: Products.Product,
    actionPayload: {
        [key: string]: any;
    },
): SagaIterator<void> {
    switch (product.type) {
        case Products.HARRY_BAG_PRODUCT:
            return yield select(BetSelectors.getBets, actionPayload.betIds);
        case Products.HARRY_SUBSCRIPTION_PRODUCT:
            return actionPayload.subscriptionResponse;
        default:
            return actionPayload.bet;
    }
}

export function* showReceipt(
    action: {
        [key: string]: any;
    },
    product: Products.Product,
): SagaIterator<void> {
    const coupon = yield select(PurchaseSelectors.getCoupon);
    const receiptData = yield call(getReceiptData, product, action.payload);

    yield put(PurchaseActions.showPurchaseReceipt(receiptData));

    // There is a bug/issue with this logic. We have a "redux-saga race" at the bottom, which would cancel this saga on FINISH_PURCHASE_FLOW. Therefore any logic below this line is never run, and we leave a dangling coupon in the redux.
    // We are not fixing this atm, and will handle it only in the new Varenne flow.
    yield take(PurchaseActions.FINISH_PURCHASE_FLOW);

    switch (product.type) {
        case SharedBetProducts.SHARED_BET_SHARE_PRODUCT:
        case SharedBetProducts.SHARED_BET_CREATE_PRODUCT:
            break;
        default:
            // Coupon might have already been removed at an earlier step
            if (coupon) yield put(CouponActions.removeCoupon(coupon));
    }
}

export function* removeLocalCoupon(gameId: string, type: CouponType): SagaIterator<void> {
    const key = yield call(CouponLocalApi.key, gameId, true, type);
    yield call(CouponLocalApi.removeCoupon, key);
}

function getPlayedAction(product: Products.Product) {
    switch (product.type) {
        case Products.HARRY_BAG_PRODUCT:
            return betActionTypes.PLAYED_BETS;
        case Products.HARRY_SUBSCRIPTION_PRODUCT:
            return PurchaseActions.HARRY_SUBSCRIPTION_PURCHASED;
        default:
            return betActionTypes.PLAYED_BET;
    }
}

function* getPlayedBetError(): SagaIterator<void> {
    while (true) {
        // Listen for every PLAYED_BET action and return error status in case of an error
        const action = yield take(betActionTypes.PLAYED_BET);
        if (action.error) {
            return action.status;
        }
    }
}

/**
 * The "confirm screen" is showing – handle bet placement
 */
export function* handlePurchase(): SagaIterator<void> {
    const product = yield select(PurchaseSelectors.getProduct);

    if (!product) {
        const purchaseState = yield select(PurchaseSelectors.getPurchaseState);
        log.error("purchaseFlowSaga:handlePurchase unexpected missing product", {
            purchaseState,
        });
        return;
    }

    const {payload, type} = yield take([
        betActionTypes.PLACE_BET,
        betActionTypes.STARTED_VARENNE_FLOW,
    ]);

    // Do not proceed with old purchase flow if we are in varenne flow
    if (type === betActionTypes.STARTED_VARENNE_FLOW) {
        // Wait for this action in order to not finish this saga early (which prevents modal from disappearing on link click)
        yield take(PurchaseActions.FINISH_PURCHASE_FLOW);
        return;
    }

    yield put(BetActions.placingBet());

    // make sure the AT (access token) is still valid before trying to place the bet
    // if it isn't the user will see an auth modal, and if they login successfully
    // `AUTH_CHECK_RESPONSE` will fire
    yield put(AuthActions.checkAuth());

    const authResult = yield take([
        AuthActions.AUTH_CHECK_RESPONSE,
        AuthActions.START_AUTHENTICATION_FLOW,
    ]);

    // if the user needs to login, wait for that and then restart the confirm flow (we don't want to
    // automatically place the bet right after they logged in, but rather give them a new chance to
    // confirm the bet)
    if (authResult.type === AuthActions.START_AUTHENTICATION_FLOW) {
        // @ts-expect-error
        yield* handlePurchase();
        return;
    }

    // if the user is logged in, try to place the bet now
    yield fork(purchaseProduct, payload);
    const playedAction = getPlayedAction(product);
    // wait for either playedAction to be dispatch, or until a played bet error occurs
    const [action] = yield race([take(playedAction), call(getPlayedBetError)]);
    const status = yield select(PurchaseSelectors.getPurchaseStatus);

    // if the bet didn't go through, restart the logic so we are ready to handle the next time the
    // user pressed "LÄGG SPEL"
    // This check only works for non-reduced bet flow. In case of reduced bets, we show errors in the "receipt" step. Therefore we execute put(showReceipt) for reduced bets even if there was an error in response
    if (status.error) {
        if (status.error.reason === "NETLOSS_LIMIT_EXCEEDED") {
            // To reduce the number of API calls RGS limits are only fetched when net loss limit error is returned by the API
            yield put(LimitsActions.fetchRgsLimits(constants.netLossLimitContext));
        }
        if (
            action?.payload?.status?.error?.reason ===
                Reason.KYC_QUESTIONNAIRE_NOT_SUBMITTED ||
            status?.error?.reason === Reason.KYC_QUESTIONNAIRE_NOT_SUBMITTED
        ) {
            /**
             * Error formats differ between private bets and harry bets, this
             * is why we check this way in case of a KYC blocked user error
             * response.
             * If a bet gets rejected on this basis, we run the KYC
             * questionnaire and skip the purchase flow.
             */
            yield call(showKycModalBeforeBet);
        }
        // @ts-expect-error
        yield* handlePurchase();
        return;
    }

    yield call(showReceipt, action, product);
}

export function* purchaseBet(
    {
        bet,
        token,
        coupon,
        reductionTerms,
    }: {
        [key: string]: any;
    },
    product: Products.Product,
): SagaIterator<void> {
    // coupon.game is too minimalistic for `purchaseReducedBet`, we need the full object
    const fullGameObject = yield select(GameSelectors.getGameById, coupon.game.id);
    const calendarGame = yield select(
        CalendarSelectors.getCalendarGameById,
        coupon.game.id,
    );
    const returnToPlayer = calendarGame?.returnToPlayer;
    const isReducedBet = Boolean(reductionTerms);
    const isNewBettingSystem = fullGameObject?.newBettingSystem;
    const isVarenneSharedBetCoupon =
        isNewBettingSystem && isSharedBetCouponProduct(product);

    let betResult;

    try {
        if (isReducedBet) {
            if (isSharedBetCouponProduct(product)) {
                betResult = yield call(
                    SharedBetApi.placeReducedSharedBet,
                    coupon,
                    reductionTerms,
                    bet.game,
                );
            } else {
                betResult = yield call(
                    BetAPI.purchaseReducedBetLegacy,
                    coupon,
                    reductionTerms,
                    fullGameObject,
                    returnToPlayer,
                    token,
                );
            }
        } else {
            bet.harryFlavour = mapHarryFlavorToLegacyBetValue(bet.harryFlavour);
            betResult = yield call(BetAPI.purchaseBet, bet, token, isNewBettingSystem);

            if (isVarenneSharedBetCoupon) {
                betResult = {
                    ...betResult,
                    data: SharedBetApiUtils.mapCouponTeamBetResult(
                        betResult.data,
                        fullGameObject,
                    ),
                };
            }
        }
    } catch (e: unknown) {
        const err = e as AtgRequestError;
        // This error seems to correlate with duplicate bet issues, which at the time of writing are
        // still unresolved. Include a list of all recently dispatched actions (type only) to help
        // with troubleshooting.

        /* HRS1-10584 - Could not place bet edge case monitoring */
        log.error("purchaseFlowSaga: purchaseBet: error", {
            error: serializeError(err),
            isReducedBet,
            systems: String(bet.systems),
            betMethod: bet.betMethod,
            couponType: coupon.type,
        });
        try {
            if (isNewBettingSystem && isReducedBet) {
                const errorMessage = {
                    code: err.response.meta.statusText,
                    error: {
                        httpCode: err.response.meta.code,
                        message: "Tekniskt fel, försök igen senare.",
                        reason: isSharedBetCouponProduct(product)
                            ? err.response.data.reason
                            : // @ts-expect-error
                              err.response.data.errors[0].reason,
                    },
                    isLoading: false,
                };

                yield put(BetActions.playedBetError(bet, errorMessage, isReducedBet));
            } else {
                const errorMessage = yield call(
                    BetAPI.getBetResponseStatus,
                    err.response,
                    isReducedBet,
                    isVarenneSharedBetCoupon,
                    bet.betMethod,
                );

                yield put(BetActions.playedBetError(bet, errorMessage, isReducedBet));
            }

            return;
        } catch (err2: unknown) {
            // this is very rare, but happens about a few times per month (usually because
            // `err.response` is `null` for some reason)
            log.error("purchaseFlowSaga: purchaseBet: nested error", {
                error: serializeError(err2),
                isReducedBet,
            });
            return;
        }
    }
    yield put(BetActions.playedBet(bet, betResult.data, isReducedBet));

    const {game} = betResult.data;
    // When placing a bet via the live area, coupons are never synced to the coupon service but
    // instead persisted to localStorage. When the bet is placed we want to "forget" the coupon, so
    // the horse markings etc. are cleared if the user refreshes the page or goes to another
    // game/race and comes back.
    if (game) yield call(removeLocalCoupon, game.id, coupon.type);

    yield put(UserActions.fetchBalance());
    yield put(SharedBetActions.clearConditions());
}

export function* purchaseAndCreateSharedBet(
    payload: {
        [key: string]: any;
    },
    product: SharedBetProducts.SharedBetCreateProduct,
): SagaIterator<void> {
    const {bet, token} = payload;

    try {
        const data = yield call(createSharedBet, token);
        // added product in receiptData, needed for sharedBetReceipts
        const receiptData = {
            ...data,
            product,
        };

        const isReduced = Boolean(product.reductionTerms);

        if (!bet.couponId) {
            bet.couponId = receiptData.couponId;
        }

        yield put(BetActions.playedBet(bet, receiptData, isReduced));
    } catch (e: unknown) {
        const error = e as AtgRequestError;
        const response = error.response ? error.response : {status: 500};
        const message = yield call(getCreateSharedBetResponseStatus, response);
        yield put(BetActions.playedBetError(bet, message));
    }
}

export function* purchaseSharedBetShare(
    payload: {
        [key: string]: any;
    },
    product: Products.Product,
): SagaIterator<void> {
    const {bet, token} = payload;
    try {
        const data = yield call(purchaseSharesSharedBet, bet, token);
        // added product in receiptData, needed for sharedBetReceipts
        const receiptData = {
            ...data,
            product,
        };

        if (!bet.couponId) {
            bet.couponId = receiptData.couponId;
        }
        yield call(sharedBetConditions, data.couponId);
        yield put(BetActions.playedBet(bet, receiptData, false));
        // @ts-expect-error
        if (product.hideReceipt) {
            yield take(PurchaseActions.FINISH_PURCHASE_FLOW);
            yield put(
                ToastActions.showToast({
                    type: AtgAlertTypes.SUCCESS,
                    message: "Tack för ditt köp",
                }),
            );
        }
    } catch (e: unknown) {
        const error = e as AtgRequestError;
        const response = error.response ? error.response : {status: 500};
        const responseStatus = yield call(getPurchaseShareResponseStatus, response);
        yield put(BetActions.playedBetError(bet, responseStatus));
    }
}

export function* purchaseShopShareProduct(
    {
        bet,
        token,
    }: {
        [key: string]: any;
    },
    product: ShopShareCouponProduct,
): SagaIterator<void> {
    const coupon = product.coupon as Top7Coupon;
    const maxStakePerSystemData = yield select(
        ShopShareSelectors.getMaxStakePerSystemData,
    );
    const {multiplied: isMaxStake} = maxStakePerSystemData;
    const {newBettingSystem} = product.shopShare.game;
    const isTop7ShopShareGame = bet.game.type === "top7";

    const getTop7Reserves = () => {
        const isAllReservesNull = bet.races[0].reserves.every(
            (reserve: Array<number>) => reserve === null,
        );
        if (isAllReservesNull) {
            return [];
        }

        const isSomeReserveNull = bet.races[0].reserves.some(
            (reserve: Array<number>) => reserve === null,
        );

        if (isSomeReserveNull) {
            return bet.races[0].reserves.filter(
                (reserve: Array<number>) => reserve !== null,
            );
        }
        return bet.races[0].reserves;
    };

    const updatedBet = {
        ...bet,
        game: {...bet.game, newBettingSystem: !!newBettingSystem},
    };

    const shopShareBet =
        bet.systemId && isTop7ShopShareGame
            ? {
                  ...updatedBet,
                  rankingGroups: getRankingGroups(bet.systemId),
                  rows: coupon?.combinations,
                  stake: newBettingSystem && coupon.stake,
                  useMaxStake: newBettingSystem && isMaxStake,
                  races: [
                      {...bet.races[0], reserves: getTop7Reserves()},
                      ...bet.races.slice(1),
                  ],
              }
            : {...updatedBet, useMaxStake: isMaxStake};

    try {
        const response = yield call(
            ShopPurchaseActions.placeShareBet,
            product,
            shopShareBet,
            token,
        );

        const getResponseData = () => {
            if (response.data.game.type === "TOP7") {
                const top7ResponseData = {
                    ...response.data,
                    boxedBets: bet.boxedBets,
                    game: {
                        ...response.data.game,
                        type: response.data.game.type.toLowerCase(),
                        id: response.data.game.id.toLowerCase(),
                    },
                };
                return top7ResponseData;
            }
            return response.data;
        };

        yield put(BetActions.playedBet(bet, getResponseData(), false));

        // this will create the new coupon of type PRIVATE, we don't want it to be SHOP_SHARED
        // User will be presented with shop-shared coupons if they exist. When the last bet is placed a PRIVATE coupon is presented for the user.

        yield put(
            CouponActions.newCoupon({
                game: product.game,
                cid: CouponUtils.nextCid(),
            }),
        );
    } catch (e: unknown) {
        const error = e as AtgRequestError;
        log.error("purchaseShopShareProduct error. ", {error: serializeError(error)});
        const responseStatus = yield call(getShopShareResponseStatus, error.response);
        yield put(BetActions.playedBetError(bet, responseStatus));
    }
}

export function* purchaseHarryBag({
    bet,
    token,
}: {
    [key: string]: any;
}): SagaIterator<void> {
    try {
        const betResult = yield call(BetAPI.purchaseHarryBag, bet, token);
        const responseBets = betResult.data;
        yield all(
            responseBets.map((bagBet: any) =>
                put(BetActions.playedBet(bet, bagBet, false)),
            ),
        );
        const betIds = map(betResult.data, "id");
        yield put(BetActions.playedBets(betIds));
        yield put(UserActions.fetchBalance());
    } catch (e: unknown) {
        const error = e as AtgRequestError;
        // @ts-expect-error
        const responseStatus = yield call(BetAPI.getBetResponseStatus, error.response);
        yield put(BetActions.playedBetError(bet, responseStatus));
    }
}

export function* purchaseSubscription({
    bet,
    token,
}: {
    [key: string]: any;
}): SagaIterator<void> {
    try {
        const betResult = yield call(BetAPI.purchaseSubscription, bet, token);
        yield put(PurchaseActions.harrySubscriptionPurchased(betResult.data));
    } catch (e: unknown) {
        const error = e as AtgRequestError;
        // @ts-expect-error
        const responseStatus = yield call(BetAPI.getBetResponseStatus, error.response);
        yield put(PurchaseActions.harrySubscriptionPurchasedError(responseStatus));
    }
}

export function* purchaseProduct(payload: {[key: string]: any}): SagaIterator<void> {
    const product = yield select(PurchaseSelectors.getProduct);
    if (!product) return;

    switch (product.type) {
        case Products.HARRY_SUBSCRIPTION_PRODUCT:
            yield call(purchaseSubscription, payload);
            return;
        case Products.HARRY_BAG_PRODUCT:
            yield call(purchaseHarryBag, payload);
            return;
        case SharedBetProducts.SHARED_BET_SHARE_PRODUCT:
            yield call(purchaseSharedBetShare, payload, product);
            return;
        case SHOP_SHARE_COUPON_PRODUCT:
            yield call(purchaseShopShareProduct, payload, product);
            return;
        case SharedBetProducts.SHARED_BET_CREATE_PRODUCT:
            yield call(purchaseAndCreateSharedBet, payload, product);
            return;
        default:
            yield call(purchaseBet, payload, product);
    }
}

// this sub-saga prepares the coupon for purchase, and decides whether to:
// a) jump straight to handlePurchase if action is RESTORE_PURCHASE_FLOW (E.g. User deposited money and is redirected back to atg.se to finish bet)
// b) show the confirmation screen (if the user has enough balance)
// c) show the deposit screen (if the user DOES NOT have enough balance)
export function* showPurchaseModal({
    type,
    product,
}: {
    [key: string]: any;
}): SagaIterator<void> {
    if (type === PurchaseActions.RESTORE_PURCHASE_FLOW) {
        yield call(handlePurchase);
        return;
    }

    if (product.type === Products.FILE_BET_PRODUCT) return;
    const {coupon} = product;

    yield put(UserActions.fetchBalance());

    const actionsToWaitFor = [
        take([
            MemberActions.MEMBER_REGISTER_FINISHED,
            RECEIVE_BALANCE,
            RECEIVE_BALANCE_ERROR,
            MemberActions.CANCELLED_LOGIN_FLOW,
        ]),
    ];

    const [actionReceived] = yield all(actionsToWaitFor);

    // If a new user try to place a game we first want to show the welcome new user modal
    // after the deposit limits are set we want to continue and show the purchase modal
    if (actionReceived.type === MemberActions.MEMBER_REGISTER_FINISHED) {
        const limitsActionsToWaitFor = [
            take([
                LimitsActions.SET_DEPOSIT_LIMITS_SUCCESS,
                LimitsActions.SET_DEPOSIT_LIMITS_LATER,
            ]),
        ];
        yield race(limitsActionsToWaitFor);
    }

    if (actionReceived.type === RECEIVE_BALANCE_ERROR) {
        return;
    }

    if (actionReceived.type === MemberActions.CANCELLED_LOGIN_FLOW) {
        // finish purchase flow if a user cancelled login at this stage
        yield put(PurchaseActions.finishPurchase());
        return;
    }

    const currentBalance = yield select(UserSelectors.getBalanceAmount);
    const isCouponProduct = product.type === Products.COUPON_PRODUCT;
    const isShopShareProduct = product.type === SHOP_SHARE_COUPON_PRODUCT;
    const isUseMaxStakePerSystemEnabled = features.isEnabled(useMaxStakePerSystem);

    let canAffordBet = true;
    let isNetLossLimitReached = false;

    if (
        isShopShareProduct &&
        product.shopShare &&
        product.shopShare.game?.newBettingSystem &&
        product.coupon &&
        isUseMaxStakePerSystemEnabled
    ) {
        const {id: shareId} = product.shopShare;
        const hasEnoughBalanceForBet = ShopUtils.hasEnoughBalanceForBet(
            product.coupon.betCost,
            product.shopShare,
        );

        if (hasEnoughBalanceForBet) {
            yield put(
                ShopShareActions.fetchMaxStakePerSystemData({
                    shareId,
                    cost: product.coupon.betCost,
                }),
            );
        }
    }

    if (isCouponProduct) {
        const cost = getCost(coupon);

        if (cost) {
            canAffordBet = currentBalance >= cost;

            isNetLossLimitReached = yield select(
                LimitsSelectors.isNetLossLimitReached(cost),
            );
        }
    }

    // If user cannot afford bet, we should start the deposit flow.
    // However, if the user has reached the net loss limit, we should first inform the user about it (handled in the modal).
    if (!canAffordBet && !isNetLossLimitReached) {
        /**
         * run kyc flow for check customer,
         * and if customer blocked and skip kyc flow (close kyc modal and not filled questionnaire)
         * hen skipped purchase flow
         */
        const isSkippedKycFlow = yield call(kycFlowBeforeBet);
        if (isSkippedKycFlow) {
            return;
        }

        const checkoutLowBalanceEvent = yield call(
            PurchaseBalanceAnalytics.checkoutLowBalance,
        );
        yield call(Analytics.deprecated_logEvent, checkoutLowBalanceEvent);

        yield put(PurchaseActions.depositStarted());
    } else {
        yield put(PurchaseActions.confirmBet(coupon));
    }

    yield call(handlePurchase);
}

function* resetPurchaseInProcess(): SagaIterator<void> {
    yield delay(500);
    yield put(PurchaseActions.resetPurchaseInProcess());
}

/*
 * Worker saga for handling the purchase flow used at the web client.
 * Note, this is not a "usual" "listener" Saga we have at the bottom of our saga files, but an actual "worker" saga
 * Listener saga that runs it is contained in purchaseSaga file.
 */
export default function* purchaseFlowSaga({
    type,
    payload,
}: PurchaseAction): SagaIterator<void> {
    // a bit confusing, but this "takeEvery" is only yielded once START_PURCHASE_FLOW or RESTORE_PURCHASE_FLOW trigger this saga from purchaseSaga.
    yield takeEvery(PurchaseActions.SHOW_PURCHASE_RECEIPT, resetPurchaseInProcess);

    // if the user ever closes the purchase flow, cancel any ongoing instance of the saga so we
    // don't accidentally update the state if an async request/response resolves later, or similar
    // (`race` will cancel all other sagas when the first one completes)
    //
    // How to test (example):
    // - open purchase modal by clicking "LÄGG SPEL"
    // - in Chrome devtools switch network speed to "Slow 3G"
    // - click "Bekräfta"
    // - close the modal **after** the bet request is made, but **before** the response comes back
    // - verify that the saga is cancelled (no crashes, logs, or other weird behavior)
    const tasks = yield race([
        // Passing down both action type in order to check if action is RESTORE_PURCHASE_FLOW in showPurchaseModal
        call(showPurchaseModal, {type, product: payload.product}),
        take(PurchaseActions.FINISH_PURCHASE_FLOW),
        take(CLOSE_ALL_MODALS),
    ]);

    // if user clicks a url, CLOSE_ALL_MODALS is dispatched. Modal closes and url redirects.
    // dispatch action happens in app.js
    if (tasks[2] !== undefined) {
        yield put(PurchaseActions.finishPurchase());
    }
}
