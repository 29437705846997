import type {SettingId, Target} from "@atg-horse-shared/startlist-table-types";
import type {PreviousStartsKeys} from "@atg-horse-shared/previous-start-types";
import type {HighlightSelection} from "@atg-horse-shared/highlight-selection-types";

export const TOGGLE_IS_STARTLIST_CUSTOMIZATION_DRAWER_OPEN =
    "startlist/toggle-is-startlist-customization-drawer-open" as const;

export const SET_CUSTOM_STARTLISTS = "startlist/set-custom-startlists" as const;

export const REMOVE_CUSTOM_STARTLIST = "startlist/remove-custom-startlist" as const;

export const EDIT_CUSTOM_STARTLIST = "startlist/edit-customstartlist" as const;

export const ADD_STARTLIST_SETTING = "startlist/add-startlist-setting" as const;

export const ADD_ALL_STARTLIST_SETTINGS = "startlist/add-all-startlist-settings" as const;

export const REMOVE_ALL_STARTLIST_SETTINGS =
    "startlist/remove-all-startlist-settings" as const;

export const REMOVE_STARTLIST_SETTING = "startlist/remove-startlist-setting" as const;

export const ADD_RESULTS_TABLE_SETTING = "startlist/add-results-table-setting" as const;

export const ADD_ALL_RESULTS_TABLE_SETTINGS =
    "startlist/add-all-results-table-settings" as const;

export const REMOVE_ALL_RESULTS_TABLE_SETTINGS =
    "startlist/remove-all-results-table-settings" as const;

export const REMOVE_RESULTS_TABLE_SETTING =
    "startlist/remove-results-table-setting" as const;

export const EDIT_RESULTS_TABLE_SETTINGS =
    "startlist/edit-results-table-settings" as const;

export const UPDATE_STARTLIST_NAME = "startlist/update-startlist-name" as const;

export const SET_CURRENT_STARTLIST_ID = "startlist/set-current-startlist-id" as const;

export const SET_SELECTED_SETTINGS = "startlist/set-selected-settings" as const;

export const TOGGLE_GLOBAL_STARTLIST_SETTING =
    "startlist/toggle-global-startlist-setting" as const;

export const TOGGLE_ALL_EXTENDED_STARTS = "startlist/toggle-all-extended-starts" as const;

export const SET_SORT_OPTIONS_STARTLIST = "startlist/set-sort-options-startlist" as const;

export const SET_SORT_OPTIONS_STARTLIST_RESULT =
    "startlist/set-sort-options-startlist-results" as const;

export const SET_SORT_OPTIONS_QUICKPLAY_STARTLIST =
    "startlist/SET_SORT_OPTIONS_QUICKPLAY_STARTLIST" as const;

export const SET_SORT_OPTIONS_QUICKPLAY_RESULT =
    "startlist/SET_SORT_OPTIONS_QUICKPLAY_RESULT" as const;

export const SET_SORT_OPTIONS_EXTENDED_STARTLIST =
    "startlist/set-sort-options-extended-startlist" as const;

export const FINISH_ONBOARDING = "startlist/finish-onboarding" as const;

export const ADD_HIGHLIGHTED_PICKS_GAME_ID = "startlist/add-highlighted-picks-game-id";

export const ADD_HIGHLIGHTED_SELECTION = "startlist/add-highlighted-selection";

export const ADD_HIGHLIGHTED_PICKS_SELECTED_RECEIPT_ID =
    "startlist/add-highlighted-picks-selected-receipt-id" as const;
export const CLOSE_ONBOARDING_ITEM = "startlist/close-onboarding-item" as const;

export type OnboardingValues = "swipe" | "compactViews" | "bettingTrends";

export type Onboardings = {
    [key in OnboardingValues]: boolean;
};

export type SetQuickplayExtendedMode = {
    isOpen: boolean;
};
export type SortOptionsStartlist = {
    order: "asc" | "desc";
    value: keyof SettingId;
};

export type SortOptionsExtendedStart = {
    order: "asc" | "desc";
    value: keyof PreviousStartsKeys;
    specialSortOrderIndex?: number; // currently only used for special sorting of distans:spår
};

export type AddStartlistSetting = {
    startlistId: string;
    settingId: keyof SettingId;
    target: Target;
};

export type AddAllStartlistSettings = {
    startlistId: string;
    selectedSettings: Array<keyof SettingId>;
    target: Target;
};

export type RemoveStartlistSetting = {
    startlistId: string;
    settingId: keyof SettingId;
    target: Target;
};

export type RemoveAllStartlistSetting = {
    startlistId: string;
    target: Target;
};

export type AddResultsTableSetting = {
    settingId: keyof SettingId;
};

export type AddAllResultsTableSettings = {
    selectedSettings: Array<keyof SettingId>;
};

export type RemoveResultsTableSetting = {
    settingId: keyof SettingId;
};

export type EditResultsTableSettings = {
    selectedSettings: Array<keyof SettingId>;
};

export type UpdateStartlistName = {
    startlistId: string;
    name: string;
};

export type CustomStartlist = {
    id: string;
    name: string;
    selectedSettings: {
        startlist: Array<keyof SettingId> | [];
        results: Array<keyof SettingId> | [];
    };
};

export type AddCustomStartlist = {
    startlist: CustomStartlist;
    target: Target;
};

export type RemoveCustomStartlist = {
    id: string;
};

export type EditCustomStartlist = {
    id: string;
    selectedSettings: Array<keyof SettingId>;
    target: Target;
};

export type GlobalStartlistSettings = {
    showRaceComments: boolean;
    showBetDistributionDecimals: boolean;
    showCompactStartlist: boolean;
    showVerticalStartlists: boolean;
};

export type GlobalStartlistSettingsFields =
    | "showRaceComments"
    | "showBetDistributionDecimals"
    | "showCompactStartlist"
    | "showVerticalStartlists";

export type HighlightedPicks = {
    gameId: string;
    selectedReceiptId: string;
    selection?: HighlightSelection;
};

export type AddHighlightedPicksGameId = string;

export type AddHighlightedPicksSelectedReceiptId = string;

export type AddHighlightedSelection = HighlightSelection;

type AddHighlightedPicksSelectedReceiptIdAction = {
    type: typeof ADD_HIGHLIGHTED_PICKS_SELECTED_RECEIPT_ID;
    payload: AddHighlightedPicksSelectedReceiptId;
};

type AddHighlightedPicksGameIdAction = {
    type: typeof ADD_HIGHLIGHTED_PICKS_GAME_ID;
    payload: AddHighlightedPicksGameId;
};

type ToggleIsStartlistCustomizationDrawerOpenAction = {
    type: typeof TOGGLE_IS_STARTLIST_CUSTOMIZATION_DRAWER_OPEN;
};

type AddHighlightedSelectionAction = {
    type: typeof ADD_HIGHLIGHTED_SELECTION;
    payload: AddHighlightedSelection;
};

type SetCustomStartlistsAction = {
    type: typeof SET_CUSTOM_STARTLISTS;
    payload: Array<CustomStartlist>;
};

type RemoveCustomStartlistAction = {
    type: typeof REMOVE_CUSTOM_STARTLIST;
    payload: RemoveCustomStartlist;
};

type EditCustomStartlistAction = {
    type: typeof EDIT_CUSTOM_STARTLIST;
    payload: EditCustomStartlist;
};

type AddStartlistSettingAction = {
    type: typeof ADD_STARTLIST_SETTING;
    payload: AddStartlistSetting;
};

type AddAllStartlistSettingsAction = {
    type: typeof ADD_ALL_STARTLIST_SETTINGS;
    payload: AddAllStartlistSettings;
};

type RemoveStartlistSettingAction = {
    type: typeof REMOVE_STARTLIST_SETTING;
    payload: RemoveStartlistSetting;
};

type RemoveAllStartlistSettingsAction = {
    type: typeof REMOVE_ALL_STARTLIST_SETTINGS;
    payload: RemoveAllStartlistSetting;
};

type AddResultsTableSettingAction = {
    type: typeof ADD_RESULTS_TABLE_SETTING;
    payload: AddResultsTableSetting;
};

type AddAllResultsTableSettingsAction = {
    type: typeof ADD_ALL_RESULTS_TABLE_SETTINGS;
    payload: AddAllResultsTableSettings;
};

type RemoveResultsTableSettingAction = {
    type: typeof REMOVE_RESULTS_TABLE_SETTING;
    payload: RemoveResultsTableSetting;
};

type RemoveAllResultsTableSettingsAction = {
    type: typeof REMOVE_ALL_RESULTS_TABLE_SETTINGS;
};

type EditResultsTableSettingsAction = {
    type: typeof EDIT_RESULTS_TABLE_SETTINGS;
    payload: EditResultsTableSettings;
};

type UpdateStartlistNameAction = {
    type: typeof UPDATE_STARTLIST_NAME;
    payload: UpdateStartlistName;
};

type SetCurrentStartlistIdAction = {
    type: typeof SET_CURRENT_STARTLIST_ID;
    payload: string;
};

type SetSortingOptionsStartlistAction = {
    type: typeof SET_SORT_OPTIONS_STARTLIST;
    payload: SortOptionsStartlist;
};

type SetSortingOptionsStartlistResultAction = {
    type: typeof SET_SORT_OPTIONS_STARTLIST_RESULT;
    payload: SortOptionsStartlist;
};

type ToggleGlobalStartlistSettingAction = {
    type: typeof TOGGLE_GLOBAL_STARTLIST_SETTING;
    payload: GlobalStartlistSettingsFields;
};

type SetSortingOptionsExtendedStartlistAction = {
    type: typeof SET_SORT_OPTIONS_EXTENDED_STARTLIST;
    payload: SortOptionsExtendedStart;
};

type ToggleAllExtendedStartsAction = {
    type: typeof TOGGLE_ALL_EXTENDED_STARTS;
};

type FinishOnboardingAction = {
    type: typeof FINISH_ONBOARDING;
};

type CloseSwipeOnboardingAction = {
    type: typeof CLOSE_ONBOARDING_ITEM;
    payload: OnboardingValues;
};

export const addHighlightedPicksGameId = (
    payload: AddHighlightedPicksGameId,
): AddHighlightedPicksGameIdAction => ({
    type: ADD_HIGHLIGHTED_PICKS_GAME_ID,
    payload,
});

export const addHighlightedSelection = (
    payload: AddHighlightedSelection,
): AddHighlightedSelectionAction => ({
    type: ADD_HIGHLIGHTED_SELECTION,
    payload,
});

export const addHighlightedPicksSelectedReceiptId = (
    payload: AddHighlightedPicksSelectedReceiptId,
): AddHighlightedPicksSelectedReceiptIdAction => ({
    type: ADD_HIGHLIGHTED_PICKS_SELECTED_RECEIPT_ID,
    payload,
});

export const toggleIsStartlistCustomizationDrawerOpen =
    (): ToggleIsStartlistCustomizationDrawerOpenAction => ({
        type: TOGGLE_IS_STARTLIST_CUSTOMIZATION_DRAWER_OPEN,
    });

export const finishOnboarding = (): FinishOnboardingAction => ({
    type: FINISH_ONBOARDING,
});

export const closeOnboardingItem = (
    payload: OnboardingValues,
): CloseSwipeOnboardingAction => ({
    type: CLOSE_ONBOARDING_ITEM,
    payload,
});

export const setCustomStartlists = (
    payload: Array<CustomStartlist>,
): SetCustomStartlistsAction => ({
    type: SET_CUSTOM_STARTLISTS,
    payload,
});

export const removeCustomStartlist = (
    payload: RemoveCustomStartlist,
): RemoveCustomStartlistAction => ({
    type: REMOVE_CUSTOM_STARTLIST,
    payload,
});

export const editCustomStartlist = (
    payload: EditCustomStartlist,
): EditCustomStartlistAction => ({
    type: EDIT_CUSTOM_STARTLIST,
    payload,
});

export const addStartlistSetting = (
    payload: AddStartlistSetting,
): AddStartlistSettingAction => ({
    type: ADD_STARTLIST_SETTING,
    payload,
});

export const addAllStartlistSettings = (
    payload: AddAllStartlistSettings,
): AddAllStartlistSettingsAction => ({
    type: ADD_ALL_STARTLIST_SETTINGS,
    payload,
});

export const removeStartlistSetting = (
    payload: RemoveStartlistSetting,
): RemoveStartlistSettingAction => ({
    type: REMOVE_STARTLIST_SETTING,
    payload,
});

export const removeAllStartlistSettings = (
    payload: RemoveAllStartlistSetting,
): RemoveAllStartlistSettingsAction => ({
    type: REMOVE_ALL_STARTLIST_SETTINGS,
    payload,
});

export const addResultsTableSetting = (
    payload: AddResultsTableSetting,
): AddResultsTableSettingAction => ({
    type: ADD_RESULTS_TABLE_SETTING,
    payload,
});

export const addAllResultsTableSettings = (
    payload: AddAllResultsTableSettings,
): AddAllResultsTableSettingsAction => ({
    type: ADD_ALL_RESULTS_TABLE_SETTINGS,
    payload,
});

export const removeResultsTableSetting = (
    payload: RemoveResultsTableSetting,
): RemoveResultsTableSettingAction => ({
    type: REMOVE_RESULTS_TABLE_SETTING,
    payload,
});

export const removeAllResultsTableSettings = (): RemoveAllResultsTableSettingsAction => ({
    type: REMOVE_ALL_RESULTS_TABLE_SETTINGS,
});

export const editResultsTableSettings = (
    payload: EditResultsTableSettings,
): EditResultsTableSettingsAction => ({
    type: EDIT_RESULTS_TABLE_SETTINGS,
    payload,
});

export const updatedStartlistName = (
    payload: UpdateStartlistName,
): UpdateStartlistNameAction => ({
    type: UPDATE_STARTLIST_NAME,
    payload,
});

export const setCurrentStartlistId = (payload: string): SetCurrentStartlistIdAction => ({
    type: SET_CURRENT_STARTLIST_ID,
    payload,
});

export const toggleGlobalStartlistSetting = (
    view: GlobalStartlistSettingsFields,
): ToggleGlobalStartlistSettingAction => ({
    type: TOGGLE_GLOBAL_STARTLIST_SETTING,
    payload: view,
});

export const setSortOptionsStartlist = (
    payload: SortOptionsStartlist,
): SetSortingOptionsStartlistAction => ({
    type: SET_SORT_OPTIONS_STARTLIST,
    payload,
});

export const setSortOptionsStartlistResult = (
    payload: SortOptionsStartlist,
): SetSortingOptionsStartlistResultAction => ({
    type: SET_SORT_OPTIONS_STARTLIST_RESULT,
    payload,
});

export const setSortOptionsExtendedStartlist = (
    payload: SortOptionsExtendedStart,
): SetSortingOptionsExtendedStartlistAction => ({
    type: SET_SORT_OPTIONS_EXTENDED_STARTLIST,
    payload,
});

export const toggleAllExtendedStarts = (): ToggleAllExtendedStartsAction => ({
    type: TOGGLE_ALL_EXTENDED_STARTS,
});

export type Types =
    | ToggleIsStartlistCustomizationDrawerOpenAction
    | SetCustomStartlistsAction
    | CloseSwipeOnboardingAction
    | FinishOnboardingAction
    | RemoveCustomStartlistAction
    | RemoveAllStartlistSettingsAction
    | EditCustomStartlistAction
    | AddStartlistSettingAction
    | AddAllStartlistSettingsAction
    | RemoveStartlistSettingAction
    | AddResultsTableSettingAction
    | AddAllResultsTableSettingsAction
    | RemoveResultsTableSettingAction
    | RemoveAllResultsTableSettingsAction
    | EditResultsTableSettingsAction
    | UpdateStartlistNameAction
    | SetCurrentStartlistIdAction
    | SetSortingOptionsStartlistAction
    | SetSortingOptionsStartlistResultAction
    | ToggleGlobalStartlistSettingAction
    | SetSortingOptionsExtendedStartlistAction
    | ToggleAllExtendedStartsAction
    | AddHighlightedPicksSelectedReceiptIdAction
    | AddHighlightedPicksGameIdAction
    | AddHighlightedSelectionAction;
